export const TradLeCata = {
    français: {
        description:'Une véritable invitation au rêve, coucher de soleil au champagne, repas servi à table et une ambiance festive et cosy à bord.',
        description2: "Un dîner exceptionnel sur le Catamaran, devant un magnifique coucher de soleil. Profitez du service et de toutes les saveurs du Poivrier dans ce cadre incroyable.",
        descriptionTitle: "Dîner incontournable sur le Catamaran du Poivrier",
        intro :"Départ pour le sunset à 18h, vous pourrez profiter du  ",
        musicien: 'musicien à bord',
        intro2:"tout en dégustant quelques amuse-bouches. Choisissez votre menu et votre pack, le repas vous sera ",
        serviTable:"servi à table.",
        intro3: "Et pour finir la soirée en beauté ... ",
        artifice: "feu d'artifice",
        intro4: "dans la baie.",
        menuUpdate: "Les menus sont susceptibles de changer selon l'arrivage. Vous pouvez confirmer lors de la réservation.",
        duration: 'Durée :',
        approx: "approx.",
        meetingPoint: "Point de rendez-vous :",
        minimumAge: 'Âge minimum :',
        shoes: "Chaussures autorisées",
        shoesExcept: "sauf talons aiguilles et semelles noires",
        dress: "Code vestimentaire :",
        dressCode: "chic décontracté",
        music: "Musiciens à bord et feux d'artifice",
        price: 'Prix :',
        menu: 'Le menu du Poivrier inclus',
        seeMenu: 'voir le menu ci-dessous',
        openings: "Saisons d'ouverture :",
        menuTitle: 'Menu Le Poivrier',
        submenuTitle1: 'Apéritif',
        beer: "Bière",
        wine: 'Vin',
        rhum: "Rhum",
        cocktails: "Cocktails",
        juices: "Jus",
        softs: "Boissons non alcoolisées",
        water: "Eau",
        submenuTitle2: "plats",
        gadjacks: "Gadjacks",
        tartare: "Tartare de poisson",
        camaron: "Camarons géants grillés",
        sausages: "Saucisses grillées",
        brochette: "Brochette de viande",
        submenuTitle3: "dessert",
        mousse: "Mousse au chocolat",
        pineapple: "Ananas rôti",
        coffee: "Café", 
        book: "réserver",
        // BY NIGHT
        golden: "menu golden", 
        appetizer: "amuse-bouches",
        petitsFours: "Petit four et canapé",
        charcuterie: "Plateau de charcuterie italienne de Marches & Latium",
        foieGras: "Foie gras de canard poêlé",
        caviar: 'Caviar Oscietre de la maison Kaviari 50gr pour 2 personnes',
        coupeChamp: "1 Coupe de champagne",
        starters: "entrée",
        tunaTartare: "Tartare de thon de notre océan ",
        course: "plat",
        lobster: 'Langouste',
        risotto: 'Risotto à la truffe du Périgord et sa langouste de St Brandon',
        fromages: 'Fromages',
        plateauFromage: "Plateau de fromages français et salade de pignons",
        tuiles: "Mousse au chocolat et sa tuile aux amandes",
        infinite: 'Menu Infinite',
        bottle: '1 bouteille de vin français pour 2',
        bottlePrestige: '1 bouteille de vin prestige pour 2',
        // PACKS
        packs: "Nos packs Boissons",
        bullesOr: "Pack Bulles d'Or",
        argent: "Pack Bulles d'Argent",
        vignoble: "Pack Vignoble",
        mojito: 'Pack Mojito',
        houblon: 'Pack Malt',
        champagne: 'champagne - Bouteille',
        cocktails: '4 cocktails au choix',
        alcoolChoix: '4 alcools au choix',
        or: "ou", 
        mignardises: "mignardises",
        foursSalés: "Fours salés",
        grandVin: 'Grands vins - Bouteille',
        // CONTACT
        // RESERVATION
        reserver: "réserver",
        para1:"Vous souhaitez passer une soirée inoubliable ? Pensez à réserver",
        strong1:"24 heures à l'avance",
        para1suite: 'pour ne pas manquer cette occasion.',
        para2: "Pour réserver, n'hésitez pas à faire votre demande directement par téléphone. Pour officialiser la réservation, un lien vous sera envoyé pour le paiement de l'acompte (min. 50%).",
        para3: "Nous vous attendrons à ",
        strong3:"18h sur le ponton du Sunset Boulevard",
        para3suite: "pour l'embarquement.",
        para4: "Atention à ne pas porter de talons aiguilles ou de semelles noires, sinon vous risquez de finir pieds nus !",
        para5:"Vous avez un imprévu et devez annuler la soirée ? Pas d'inquiétude, l'accompte est remboursable jusqu'à 24 heures avant la soirée.",
        // MONTHS
        jan: "J",
        feb: "F",
        mar: "M",
        apr:"A",
        may: "M",
        june: "J",
        jul: "J",
        aug: "A",
        sept: "S",
        oct: "O",
        nov: "N",
        dec: "D",
    },
    english: {
        description: "A real invitation to dream, champagne sunset, meal served at the table and a festive and cozy atmosphere on board.",
        description2 : "An exceptional dinner on the Catamaran, in front of a magnificent sunset. Enjoy the service and all the flavors of Le Poivrier in this incredible setting.",
        descriptionTitle: "Incredible diner on Le Catamaran du Poivrier",
        intro :"Departure for sunset at 6 p.m., you can enjoy the  ",
        musicien: 'musician on board',
        intro2:"while tasting some appetizers. Choose your menu and your pack, the meal will be ",
        serviTable:"served at the table.",
        intro3: "And to end the evening in style...",
        artifice: "fireworks",
        intro4: "in the bay.",
        menuUpdate: "Menus are subject to change depending on availability. You can confirm when booking.",
        duration: 'Duration:',
        approx: "approx.",
        meetingPoint: "Meeting Point:",
        minimumAge: 'Minimum Age:',
        music: "Musicians on board and fireworks",
        price: 'Prix:',
        shoes: "Shoes allowed",
        shoesExcept: "except stiletto heels and black soles",
        dress: "Dress code:",
        dressCode: "casual smart",
        menu: 'le Poivrier Menu Included:',
        seeMenu: 'see menu below',
        openings: "Opening seasons:",
        menuTitle: 'Le Poivrier Menu ',
        submenuTitle1: 'Aperitif',
        beer: "Beer",
        wine: 'wine',
        rhum: "rhum",
        cocktails: "cocktails",
        juices: "juices",
        softs: "softs drinks",
        water: "water",
        submenuTitle2: "course",
        gadjacks: "Gadjacks",
        tartare: "Fish tartare",
        camaron: "Grilled giant prawns",
        sausages: "Grilled sausages",
        brochette: "Meat skewers",
        submenuTitle3: "dessert",
        mousse: "Chocolate mousse",
        pineapple: "Roasted pineapple",
        coffee: "Coffee",
        book: "book now",
        golden: "golden menu ",
        appetizer: "Appetizers",
        petitsFours: "Petits fours and canapés",
        charcuterie: "Italian charcuterie platter from Marche & Latium",
        foieGras: "Pan-fried duck foie gras",
        caviar: 'Caviar Oscietre from the Kaviari house 50gr for 2 people',
        coupeChamp: "1 cup of champagne",
        starters: "starters",
        tunaTartare: "Tuna tartare from our ocean",
        course: "course",
        lobster: 'Lobster',
        risotto: 'Périgord truffle risotto with its St Brandon Lobster',
        fromages: 'Cheeses',
        plateauFromage: "French cheese platter and its salad with pine nuts",
        tuiles: "Chocolate mousse with its almond biscuit",
        infinite: 'Infinite menu',
        bottle: '1 bottle of French wine for 2',
        bottlePrestige: '1 bottle of prestige wine for 2',
        // PACKS
        packs: "Our Drinks Packs",
        bullesOr: "Pack Golden Bubles",
        argent: "Pack Silver Bubbles",
        vignoble: "Pack Vineyard",
        mojito: 'Pack Mojito',
        houblon: 'Pack Malt',
        cocktails: '4 cocktails of your choice',
        alcoolChoix: '4 alcools of your choice',
        champagne: 'champagne - Bottle',
        or: "or", 
        mignardises: "mignardises",
        foursSalés: "Savouries",
        grandVin: 'Great Wines - Bottle',
        // CONTACT
        // RESERVATION
        reserver: "reservation",
        para1:"Do you want to spend an unforgettable evening? Remember to book",
        strong1:"24 hours in advance",
        para1suite: "so you don't miss this opportunity",
        para2: "Please do not hesitate to contact us directly by phone to book your evening. To formalize the reservation, a link will be sent to you for the payment of the deposit (min. 50%).",
        para3: "We will be waiting for you at ",
        strong3:"6 pm on the Sunset Boulevard pontoon ",
        para3suite: "for boarding.",
        para4: "Be careful not to wear stiletto heels or black soles, otherwise you risk ending up barefoot!",
        para5:"You have an unforeseen event and have to cancel the evening? Don't worry, the deposit is refundable up to 24 hours before the event.",
        jan: "J",
        feb: "F",
        mar: "M",
        apr:"A",
        may: "M",
        june: "J",
        jul: "J",
        aug: "A",
        sept: "S",
        oct: "O",
        nov: "N",
        dec: "D",
    },

    pусский: {
        description: "Путевка в мечту,закаты с шампанским,сервис  а ла карт ,праздничная и дружеская атмосфера на борту",
        description2: 'Магический закат и незабываемый ужин на катамаране. Насладитесь приятным сервисом и мастерством шеф\а\ов "Poivrier"',
        descriptionTitle: "незабываемый ужин на катамаране",
        intro : "Отправление на закате в 18.00",
        musicien: "Живая музыка на  борту",
        intro2: "апперитив и закуски . Выбирайте различные типы  меню на  ваш  вкус .",
        serviTable: "блюда подаются к столу а ла карт ",
        intro3: "В завершение вечера ",
        artifice: "фейерверк",
        intro4: "в заливе ",
        menuUpdate: "Возможны не значительные изменения в меню в зависимости от доступности продуктов и сезона",
        duration: "Длительность",
        approx: "o",
        meetingPoint: "Место встречи ",
        minimumAge: "Минимальный возраст ",
        shoes: "обувь разрешена",
        shoesExcept: "за исключением шпилек и чёрной подошвы",
        dress: "дресс код ",
        dressCode: "повседневный шик",
        music: "живая музыка на борту и фейерверк",
        price: "Цена",
        menu: 'включая меню "Le Poivrier" ',
        seeMenu: "смотреть  меню ниже",
        openings: "сезоны открытия",
        menuTitle: 'Меню "Le Poivrier"',
        
        submenuTitle1: 'Аперетив',
        beer: "Пиво",
        wine: 'Вино',
        rhum: "Ром",
        cocktails: "Коктейли",
        juices: "Сок",
        softs: "Безалкогольные напитки",
        water: "Вода",
        submenuTitle2: "Блюда",
        gadjacks: "Гэдджеки",
        tartare: "Тартар из рыбы",
        camaron: "Жареные гигантские креветки",
        sausages: "Жареные сосиски",
        brochette: "Мясной шашлык",
        submenuTitle3: "Десерт",
        mousse: "Шоколадный мусс",
        pineapple: "Ананас жареный",
        coffee: "Кофе", 
        book: "Бронировать",

        golden: 'Меню "Золотое"',
        appetizer: "Закуски",
        petitsFours: "Горячие и холодные канапэ",
        charcuterie: "Итальянская мясная нарезка от Marches & Latium",
        foieGras: "Обжаренная гусинная печень",
        caviar: "Чёрная икра осётр Kaviari 50 г на двоих",
        coupeChamp: "1 бокал шампанского",
        starters: "Стартеры",
        tunaTartare: "Тартар тунца из нашего океана",
        course: "блюда",
        lobster: "Омар",
        risotto: "Ризотто с трюфелем Périgord и омаром St.Brandon",
        fromages: "Сыры",
        plateauFromage: "Сырная тарелка",
        tuiles: "Шоколадный мус с миндальным кружевом",
        infinite: 'Меню "Infinité"',
        bottle: "1 бт францзского вина на двоих",
        bottlePrestige: "1 бт престижного вина на двоих",
        // PACKS: Пакеты
        packs: "Наши пакеты для напитков",
        bullesOr: `Пакет "Bulles d'Or"` ,
        argent: `Пакет "Bulles d'Argent`,
        vignoble: `Пакет "Vignoble"`,
        mojito: `Пакет "Mojito"`,
        houblon: `Пакет "Malt"`,
        champagne: "шампанское - бутылка" ,
        cocktails: "4 коктеля на выбор",
        alcoolChoix: "4 спиртного на выбор",
        or: "либо",
        mignardises: "мини дессерты",
        foursSalés: "горячие канапэ",
        grandVin: "Вина",
        // RESERVATION
        reserver: "Резервация",
        para1: "Вы желаете провести незабываемый вечер? Позаботьтесь о резервации",					
        strong1: "За 24 часа", 
        para1suite: "чтоб не упустить случай",
        para2: "прямая резервация по телефону. Для подтверждения резервации необходимо сделать оплату по ссылке",
        para3: "Мы вас ждём в",
        strong3: "18.00 на пирсе Sunset Boulevard",
        para3suite: "для отправлния",
        para4: "Не забудте о требовании к обуви а то останитесь босиком !",
        para5: "Изменились планы и хотите отменить заказ ? Нет проблем, предоплата возвращается при условии отмены за 24 часа до предприятия.",
        jan: "Я",
        feb: "Ф",
        mar: "М",
        apr:"А",
        may: "М",
        june: "И",
        jul: "И",
        aug: "А",
        sept: "С",
        oct: "О",
        nov: "H",
        dec: "Д",
    }
}
