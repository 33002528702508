import React from 'react'
 
import Footer from './layout/Footer';
import WSPGallery from './subcomponents/WSPGallery'

import {TradNavbar} from '../components/translator/tradNavbar'
import { useLanguageStore } from '../components/subcomponents/LanguageStore';


// GALLERY PICTURES
import LeJardin from '../assets/pictures/le_jardin_entrée.jpeg'
///JARDIN
import Sushis from '../assets/pictures/le_jardin/gallery/sushis.jpeg'
import Balancoire from '../assets/pictures/le_jardin/gallery/balancoire.jpg'
import fresh from '../assets/pictures/le_jardin/shooting/fresh_juice.jpg'
import CarpaccioZoom from '../assets/pictures/le_jardin/shooting/carpaccio_zoom.jpg'
import Citron from '../assets/pictures/le_jardin/gallery/citron.jpg'
import Cote2BoeufClose from '../assets/pictures/le_jardin/gallery/cote_de_boeuf_close.jpg'
import CurasoLand from '../assets/pictures/le_jardin/gallery/curaso_land.jpg'
import hutte_nuit from '../assets/pictures/le_jardin/gallery/hutte_nuit.jpg'
import hutte1 from '../assets/pictures/le_jardin/shooting/hutte1.jpg'
import interieur from '../assets/pictures/le_jardin/gallery/interieur.jpg'
import langouste_pasta from '../assets/pictures/le_jardin/shooting/langouste_pasta.jpg'
import map from '../assets/pictures/le_jardin/gallery/map.jpg'
import mojito from '../assets/pictures/le_jardin/shooting/mojito.jpg'
import rainbow_land from '../assets/pictures/le_jardin/gallery/rainbow_land.jpg'
import rhums from '../assets/pictures/le_jardin/shooting/rhums.jpg'
import risotto_portrait from '../assets/pictures/le_jardin/gallery/risotto_portrait.jpg'
import risotto_portrait_2 from '../assets/pictures/le_jardin/gallery/risotto_portrait_2.jpg'
import table_dezoom from '../assets/pictures/le_jardin/shooting/table_dezoom.jpg'
import table from '../assets/pictures/le_jardin/gallery/table.jpg'
import tiramisu from '../assets/pictures/le_jardin/gallery/tiramisu.jpg'
import un_poivrier_nuit from '../assets/pictures/le_jardin/gallery/un_poivrier_nuit.jpg'
import un_poivrier from '../assets/pictures/le_jardin/gallery/un_poivrier.jpg'

import allee from '../assets/pictures/le_jardin/shooting/allee.jpg'
import desserts from '../assets/pictures/le_jardin/shooting/desserts.jpg'
import entree_nuit from '../assets/pictures/le_jardin/shooting/entree_nuit.jpg'
import entree_portrait_cut from '../assets/pictures/le_jardin/shooting/entree_portrait_cut.jpg'
import fish_table from '../assets/pictures/le_jardin/shooting/fish_table.jpg'
import ile_flottante from '../assets/pictures/le_jardin/shooting/ile_flottante.jpg'
import jardin_palette from '../assets/pictures/le_jardin/shooting/jardin_palette.jpg'
import langouste_middle_zoom from '../assets/pictures/le_jardin/shooting/langouste_middle_zoom.jpg'
import le_jardin from '../assets/pictures/le_jardin/shooting/le_jardin.jpg'

import tables_nuit from '../assets/pictures/le_jardin/shooting/tables_nuit.jpg'
import table_nuit from '../assets/pictures/le_jardin/shooting/table_nuit.jpg'
import mousse_choco from '../assets/pictures/le_jardin/shooting/mousse_choco.jpg'
import terrasse from '../assets/pictures/le_jardin/shooting/terrasse.jpg'
import voyageur_nuit from '../assets/pictures/le_jardin/shooting/voyageur_nuit.jpg'

//RIVIERE
import AlleeRiviere from "../assets/pictures/la_riviere/allee_riviere.jpg"
import Camping from "../assets/pictures/la_riviere/camping.jpg"
import Chapito from "../assets/pictures/la_riviere/chapito.jpg"
import Cloche from "../assets/pictures/la_riviere/cloche.jpg"
import EntreeDezoom from "../assets/pictures/la_riviere/entree_dezoom.jpg"
import EntreePaysage from "../assets/pictures/la_riviere/entree_paysage.jpg"
import EntreePortrait from "../assets/pictures/la_riviere/entree_portrait.jpg"
import PoivrierDore from "../assets/pictures/la_riviere/gros_poivrier_dore.jpg"
import JardinRiviere from "../assets/pictures/la_riviere/jardin_riviere.jpg"
import LongTable from "../assets/pictures/la_riviere/long_table.jpg"
import Love from "../assets/pictures/la_riviere/love.jpg"
import Parasol from "../assets/pictures/la_riviere/parasol.jpg"
import Planches from "../assets/pictures/la_riviere/planches.jpg"
import Riviere from "../assets/pictures/la_riviere/riviere.jpg"
import Stairs from "../assets/pictures/la_riviere/stairs.jpg"
import TableSolo from '../assets/pictures/la_riviere/table_solo.jpg'
import UnderTree from "../assets/pictures/la_riviere/under_tree.jpg"
import VueRiviere from '../assets/pictures/la_riviere/vue_riviere.jpg' 

/// LA PLAGE
import WhiteTables from "../assets/pictures/la_plage/2white_tables.jpg"
import BehindTreePaysage from "../assets/pictures/la_plage/behind_tree_paysage.jpg"
import BehindTree from "../assets/pictures/la_plage/behind_tree.jpg"
import Deck from "../assets/pictures/la_plage/deck.jpg"
import Direction from "../assets/pictures/la_plage/direction.jpg"
import Fauteuils from "../assets/pictures/la_plage/fauteuils.jpg"
import LaPlage from "../assets/pictures/la_plage/la_plage.jpg"
import PlageEntree from "../assets/pictures/la_plage/laplage_entree.jpg"
import PlageSeatBeach from "../assets/pictures/la_plage/laplage_seatbeach.jpg"
import Leafs from "../assets/pictures/la_plage/leafs.jpg"
import BeachView from "../assets/pictures/la_plage/plage_beach_view.jpg"
import PlageInterieur from "../assets/pictures/la_plage/plage_interieur.jpg"
import PlageLogo from "../assets/pictures/la_plage/plage_logo.jpg"
import PlagePeople from '../assets/pictures/la_plage/plage_people.jpg'
import PlagePeople2 from "../assets/pictures/la_plage/plage_people2.jpg"
import PlageSerre from "../assets/pictures/la_plage/plage_serre.jpg"
import PlageStairs from "../assets/pictures/la_plage/plage_stairs.jpg"
import PlageTerrasse from "../assets/pictures/la_plage/plage_terrasse.jpg"
import PlageTerrasseZoom from "../assets/pictures/la_plage/plage_terrasse_zoom.jpg"
import PlageWine from "../assets/pictures/la_plage/plage_wine.jpg"
import PlageWood from "../assets/pictures/la_plage/plage_wood.jpg"
import Rayures from "../assets/pictures/la_plage/rayures.jpg"
import Rocks from "../assets/pictures/la_plage/rocks.jpg"
import Totem from "../assets/pictures/la_plage/totem.jpg"
import WhiteTable from "../assets/pictures/la_plage/white_table.jpg"
import WhiteChairs from '../assets/pictures/la_plage/whites_chairs_paysage.jpg'

/// ILE PLATE
import Allee from '../assets/pictures/lile_plate/allee.jpg'
import Band from '../assets/pictures/lile_plate/band.jpg'
import CoinDeMire from '../assets/pictures/lile_plate/coin_de_mire.jpg'
import Langouste from '../assets/pictures/lile_plate/langouste.jpg'
import ModelsBeach from '../assets/pictures/lile_plate/models_beach.jpg'
import NoFinger from '../assets/pictures/lile_plate/nofinger.jpg'
import Panneau from '../assets/pictures/lile_plate/panneau.jpg'
import ParasolIlePlate from '../assets/pictures/lile_plate/parasol.jpg'
import PlageHaut from '../assets/pictures/lile_plate/plage_haut.jpg'
import RestoHaut from '../assets/pictures/lile_plate/resto_haut.jpg'
import TableBeach from '../assets/pictures/lile_plate/table_beach.jpg'
import TableInterieur from '../assets/pictures/lile_plate/table_interieur.jpg'
import Tables from '../assets/pictures/lile_plate/tables.jpeg'
import TablesBeach from '../assets/pictures/lile_plate/tables_beach.jpg'
import WalkingBeach from '../assets/pictures/lile_plate/walking_beach.jpg'


// COMPRESSED
///JARDIN
import BalancoireCompressed from '../assets/pictures/le_jardin/gallery/compressed/balancoire.jpg'
import freshCompressed from '../assets/pictures/le_jardin/shooting/compressed/fresh_juice.jpg'
import CarpaccioZoomCompressed from '../assets/pictures/le_jardin/shooting/compressed/carpaccio_zoom.jpg'
import CitronCompressed from '../assets/pictures/le_jardin/gallery/compressed/citron.jpg'
import Cote2BoeufCloseCompressed from '../assets/pictures/le_jardin/gallery/compressed/cote_de_boeuf_close.jpg'
import CurasoLandCompressed from '../assets/pictures/le_jardin/gallery/compressed/curaso_land.jpg'
import hutte_nuitCompressed from '../assets/pictures/le_jardin/gallery/compressed/hutte_nuit.jpg'
import hutte1Compressed from '../assets/pictures/le_jardin/shooting/compressed/hutte1.jpg'
import interieurCompressed from '../assets/pictures/le_jardin/gallery/compressed/interieur.jpg'
import langouste_pastaCompressed from '../assets/pictures/le_jardin/shooting/compressed/langouste_pasta.jpg'
import mapCompressed from '../assets/pictures/le_jardin/gallery/compressed/map.jpg'
import mojitoCompressed from '../assets/pictures/le_jardin/shooting/compressed/mojito.jpg'
import rainbow_landCompressed from '../assets/pictures/le_jardin/gallery/compressed/rainbow_land.jpg'
import rhumsCompressed from '../assets/pictures/le_jardin/shooting/compressed/rhums.jpg'
import risotto_portraitCompressed from '../assets/pictures/le_jardin/gallery/compressed/risotto_portrait.jpg'
import risotto_portrait_2Compressed from '../assets/pictures/le_jardin/gallery/compressed/risotto_portrait_2.jpg'
import table_dezoomCompressed from '../assets/pictures/le_jardin/shooting/compressed/table_dezoom.jpg'
import tableCompressed from '../assets/pictures/le_jardin/gallery/compressed/table.jpg'
import tiramisuCompressed from '../assets/pictures/le_jardin/gallery/compressed/tiramisu.jpg'
import un_poivrier_nuitCompressed from '../assets/pictures/le_jardin/gallery/compressed/un_poivrier_nuit.jpg'
import un_poivrierCompressed from '../assets/pictures/le_jardin/gallery/compressed/un_poivrier.jpg'

import alleeCompressed from '../assets/pictures/le_jardin/shooting/compressed/allee.jpg'
import dessertsCompressed from '../assets/pictures/le_jardin/shooting/compressed/desserts.jpg'
import entree_nuitCompressed from '../assets/pictures/le_jardin/shooting/compressed/entree_nuit.jpg'
import entree_portrait_cutCompressed from '../assets/pictures/le_jardin/shooting/compressed/entree_portrait_cut.jpg'
import fish_tableCompressed from '../assets/pictures/le_jardin/shooting/compressed/fish_table.jpg'
import ile_flottanteCompressed from '../assets/pictures/le_jardin/shooting/compressed/ile_flottante.jpg'
import jardin_paletteCompressed from '../assets/pictures/le_jardin/shooting/compressed/jardin_palette.jpg'
import langouste_middle_zoomCompressed from '../assets/pictures/le_jardin/shooting/compressed/langouste_middle_zoom.jpg'
import le_jardinCompressed from '../assets/pictures/le_jardin/shooting/compressed/le_jardin.jpg'

import tables_nuitCompressed from '../assets/pictures/le_jardin/shooting/compressed/tables_nuit.jpg'
import table_nuitCompressed from '../assets/pictures/le_jardin/shooting/compressed/table_nuit.jpg'
import mousse_chocoCompressed from '../assets/pictures/le_jardin/shooting/compressed/mousse_choco.jpg'
import terrasseCompressed from '../assets/pictures/le_jardin/shooting/compressed/terrasse.jpg'
import voyageur_nuitCompressed from '../assets/pictures/le_jardin/shooting/compressed/voyageur_nuit.jpg'

//RIVIERE
import AlleeRiviereCompressed from "../assets/pictures/la_riviere/compressed/allee_riviere_compressed.jpg"
import CampingCompressed  from "../assets/pictures/la_riviere/compressed/camping_compressed.jpg"
import ChapitoCompressed  from "../assets/pictures/la_riviere/compressed/chapito_compressed.jpg"
import ClocheCompressed  from "../assets/pictures/la_riviere/compressed/cloche_compressed.jpg"
import EntreeDezoomCompressed  from "../assets/pictures/la_riviere/compressed/entree_dezoom_compressed.jpg"
import EntreePaysageCompressed  from "../assets/pictures/la_riviere/compressed/entree_paysage_compressed.jpg"
import EntreePortraitCompressed  from "../assets/pictures/la_riviere/compressed/entree_portrait_compressed.jpg"
import PoivrierDoreCompressed  from "../assets/pictures/la_riviere/compressed/gros_poivrier_dore_compressed.jpg"
import JardinRiviereCompressed  from "../assets/pictures/la_riviere/compressed/jardin_riviere_compressed.jpg"
import LongTableCompressed  from "../assets/pictures/la_riviere/compressed/long_table_compressed.jpg"
import LoveCompressed  from "../assets/pictures/la_riviere/compressed/love_compressed.jpg"
import ParasolCompressed  from "../assets/pictures/la_riviere/compressed/parasol_compressed.jpg"
import PlanchesCompressed  from "../assets/pictures/la_riviere/compressed/planches_compressed.jpg"
import RiviereCompressed  from "../assets/pictures/la_riviere/compressed/riviere_compressed.jpg"
import StairsCompressed  from "../assets/pictures/la_riviere/compressed/stairs_compressed.jpg"
import TableSoloCompressed  from '../assets/pictures/la_riviere/compressed/table_solo_compressed.jpg'
import UnderTreeCompressed  from "../assets/pictures/la_riviere/compressed/under_tree_compressed.jpg"
import VueRiviereCompressed  from '../assets/pictures/la_riviere/compressed/vue_riviere_compressed.jpg' 

/// LA PLAGE
import WhiteTablesCompressed from "../assets/pictures/la_plage/compressed/2white_tables_compressed.jpg"
import BehindTreePaysageCompressed from "../assets/pictures/la_plage/compressed/behind_tree_paysage_compressed.jpg"
import BehindTreeCompressed from "../assets/pictures/la_plage/compressed/behind_tree_compressed.jpg"
import DeckCompressed from "../assets/pictures/la_plage/compressed/deck_compressed.jpg"
import DirectionCompressed from "../assets/pictures/la_plage/compressed/direction_compressed.jpg"
import FauteuilsCompressed from "../assets/pictures/la_plage/compressed/fauteuils_compressed.jpg"
import LaPlageCompressed from "../assets/pictures/la_plage/compressed/la_plage_compressed.jpg"
import PlageEntreeCompressed from "../assets/pictures/la_plage/compressed/laplage_entree_compressed.jpg"
import PlageSeatBeachCompressed from "../assets/pictures/la_plage/compressed/laplage_seatbeach_compressed.jpg"
import LeafsCompressed from "../assets/pictures/la_plage/compressed/leafs_compressed.jpg"
import BeachViewCompressed from "../assets/pictures/la_plage/compressed/plage_beach_view_compressed.jpg"
import PlageInterieurCompressed from "../assets/pictures/la_plage/compressed/plage_interieur_compressed.jpg"
import PlageLogoCompressed from "../assets/pictures/la_plage/plage_logo.jpg"
import PlagePeopleCompressed from '../assets/pictures/la_plage/compressed/plage_people_compressed.jpg'
import PlagePeople2Compressed from "../assets/pictures/la_plage/compressed/plage_people2_compressed.jpg"
import PlageSerreCompressed from "../assets/pictures/la_plage/compressed/plage_serre_compressed.jpg"
import PlageStairsCompressed from "../assets/pictures/la_plage/compressed/plage_stairs_compressed.jpg"
import PlageTerrasseCompressed from "../assets/pictures/la_plage/compressed/plage_terrasse_compressed.jpg"
import PlageTerrasseZoomCompressed from "../assets/pictures/la_plage/compressed/plage_terrasse_zoom_compressed.jpg"
import PlageWineCompressed from "../assets/pictures/la_plage/compressed/plage_wine_compressed.jpg"
import PlageWoodCompressed from "../assets/pictures/la_plage/compressed/plage_wood_compressed.jpg"
import RayuresCompressed from "../assets/pictures/la_plage/compressed/rayures_compressed.jpg"
import RocksCompressed from "../assets/pictures/la_plage/compressed/rocks_compressed.jpg"
import TotemCompressed from "../assets/pictures/la_plage/compressed/totem_compressed.jpg"
import WhiteTableCompressed from "../assets/pictures/la_plage/compressed/white_table_compressed.jpg"
import WhiteChairsCompressed from '../assets/pictures/la_plage/compressed/whites_chairs_paysage_compressed.jpg'

function Gallery() {
    const language = useLanguageStore((state) => state.language)
    const galleryImages= [
        // JARDIN
        {img: entree_portrait_cut},
        {img: le_jardin},
        {img: jardin_palette},
        {img: CurasoLand},
        {img: fresh},
        {img: Citron},
        {img: rainbow_land},
        {img: hutte1},
        {img: mojito},
        {img: rhums},
        {img: CarpaccioZoom},
        {img: langouste_middle_zoom},
        {img: langouste_pasta},
        {img: interieur},
        {img: Cote2BoeufClose},
        {img: fish_table},
        {img: risotto_portrait},
        {img: allee},
        {img: risotto_portrait_2},
        {img: table},
        {img: Sushis},
        {img: table_dezoom},
        {img: un_poivrier},
        {img: ile_flottante},
        {img: mousse_choco},
        {img: terrasse},
        {img: tiramisu},
        {img: table_nuit},
        {img: hutte_nuit},
        {img: desserts},
        {img: voyageur_nuit},
        {img: map},
        {img: entree_nuit},
        {img: Balancoire},
        {img: un_poivrier_nuit},
        {img: tables_nuit},
        //ILE PLATE
        {img: NoFinger},
        {img: PlageHaut},
        {img: Panneau},
        {img: RestoHaut},
        {img: Tables},
        {img: TableInterieur},
        {img: Langouste},
        {img: Allee},
        {img: Band},
        {img: ModelsBeach},
        {img: TablesBeach},
        {img: TableBeach},
        {img: ParasolIlePlate},
        {img: CoinDeMire},
        {img: WalkingBeach}, 
        // RIVIERE
        {img: EntreeDezoom} ,
        {img: EntreePaysage},
        {img: EntreePortrait} ,
        {img: AlleeRiviere} ,
        {img: Camping} ,
        {img: Chapito} ,
        {img: Cloche} ,
        {img: PoivrierDore} ,
        {img: JardinRiviere} ,
        {img: LongTable} ,
        {img: Love} ,
        {img: Parasol},
        {img: Planches} ,
        {img: Riviere} ,
        {img: Stairs} ,
        {img: TableSolo} ,
        {img: UnderTree},
        {img: VueRiviere},
         // PLAGE
         {img: PlageEntree} ,
        {img: WhiteTables} ,
        {img: BehindTreePaysage} ,
        {img: BehindTree} ,
        {img: Deck} ,
        {img: Direction} ,
        {img: Fauteuils} ,
        {img: LaPlage} ,
        {img: PlageSeatBeach} ,
        {img: Leafs} ,
        {img: BeachView} ,
        {img: PlageInterieur} ,
        {img: PlageLogo} ,
        {img: PlagePeople} ,
        {img: PlagePeople2},
        {img: PlageSerre} ,
        {img: PlageStairs} ,
        {img: PlageTerrasse} ,
        {img: PlageTerrasseZoom} ,
        {img: PlageWine} ,
        {img: PlageWood} ,
        {img: Rayures} ,
        {img: Rocks} ,
        {img: Totem} ,
        {img: WhiteTable} ,
        {img: WhiteChairs} ,

      ]
      const compressedGallery = [
        {img: entree_portrait_cutCompressed},
        {img: le_jardinCompressed},
        {img: jardin_paletteCompressed},
        {img: CurasoLandCompressed},
        {img: freshCompressed},
        {img: CitronCompressed},
        {img: rainbow_landCompressed},
        {img: hutte1Compressed},
        {img: mojitoCompressed},
        {img: rhumsCompressed},
        {img: CarpaccioZoomCompressed},
        {img: langouste_middle_zoomCompressed},
        {img: langouste_pastaCompressed},
        {img: interieurCompressed},
        {img: Cote2BoeufCloseCompressed},
        {img: fish_tableCompressed},
        {img: risotto_portraitCompressed},
        {img: alleeCompressed},
        {img: risotto_portrait_2Compressed},
        {img: tableCompressed},
        {img: Sushis},
        {img: table_dezoomCompressed},
        {img: un_poivrierCompressed},
        {img: ile_flottanteCompressed},
        {img: mousse_chocoCompressed},
        {img: terrasseCompressed},
        {img: tiramisuCompressed},
        {img: table_nuitCompressed},
        {img: hutte_nuitCompressed},
        {img: dessertsCompressed},
        {img: voyageur_nuitCompressed},
        {img: mapCompressed},
        {img: entree_nuitCompressed},
        {img: BalancoireCompressed},
        {img: un_poivrier_nuitCompressed},
        {img: tables_nuitCompressed},
        //ILE PLATE
        {img: NoFinger},
        {img: PlageHaut},
        {img: Panneau},
        {img: RestoHaut},
        {img: Tables},
        {img: TableInterieur},
        {img: Langouste},
        {img: Allee},
        {img: Band},
        {img: ModelsBeach},
        {img: TablesBeach},
        {img: TableBeach},
        {img: ParasolIlePlate},
        {img: CoinDeMire},
        {img: WalkingBeach}, 
        // RIVIERE
        {img: EntreeDezoomCompressed} ,
        {img: EntreePaysageCompressed},
        {img: EntreePortraitCompressed} ,
        {img: AlleeRiviereCompressed} ,
        {img: CampingCompressed} ,
        {img: ChapitoCompressed} ,
        {img: ClocheCompressed} ,
        {img: PoivrierDoreCompressed} ,
        {img: JardinRiviereCompressed} ,
        {img: LongTableCompressed} ,
        {img: LoveCompressed} ,
        {img: ParasolCompressed},
        {img: PlanchesCompressed} ,
        {img: RiviereCompressed} ,
        {img: StairsCompressed} ,
        {img: TableSoloCompressed} ,
        {img: UnderTreeCompressed},
        {img: VueRiviereCompressed},
        // PLAGE
        {img: PlageEntreeCompressed} ,
        {img: WhiteTablesCompressed} ,
        {img: BehindTreePaysageCompressed} ,
        {img: BehindTreeCompressed} ,
        {img: DeckCompressed} ,
        {img: DirectionCompressed} ,
        {img: FauteuilsCompressed} ,
        {img: LaPlageCompressed} ,
        {img: PlageSeatBeachCompressed} ,
        {img: LeafsCompressed} ,
        {img: BeachViewCompressed} ,
        {img: PlageInterieurCompressed} ,
        {img: PlageLogoCompressed} ,
        {img: PlagePeopleCompressed} ,
        {img: PlagePeople2Compressed},
        {img: PlageSerreCompressed} ,
        {img: PlageStairsCompressed} ,
        {img: PlageTerrasseCompressed} ,
        {img: PlageTerrasseZoomCompressed} ,
        {img: PlageWineCompressed} ,
        {img: PlageWoodCompressed} ,
        {img: RayuresCompressed} ,
        {img: RocksCompressed} ,
        {img: TotemCompressed} ,
        {img: WhiteTableCompressed} ,
        {img: WhiteChairsCompressed} ,
      ]
      document.title="Le POIVRIER";
    document.head.querySelector("[id~=page-description][content]").content="Le Poivrier Gallery, check-out our restaurants, four settings: an island, a beach, a river and a garden.";
  return (
    <div className="page2_container">
       <div className="fake_scrolling">
       <div className="gallery_hero"></div>
       <div className="galelry_title_container">
            <h2 className='gallery_title'>{TradNavbar[language].gallery}</h2>
            <h3>{TradNavbar[language].jardin} - {TradNavbar[language].ilePlate} - {TradNavbar[language].riviere} - {TradNavbar[language].plage}</h3>
       </div>
       
       </div>
        <WSPGallery galleryImages={galleryImages} compressedGallery={compressedGallery}/>
        <Footer/>
    </div>
  )
}

export default Gallery
