import React from 'react'
import { NavLink } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"

import Footer from './layout/FooterRiviere'
import ContactForm from './subcomponents/ContactForm'

// LANGUAGE
import { useLanguageStore } from './subcomponents/LanguageStore';
import {tradJardin} from './translator/le_jardin/tradLeJardin'
import {vocab} from './translator/vocab'
import {contactLeJardin} from "./translator/le_jardin/contactLeJardin"
import {tradFooter, week} from "./translator/le_jardin/tradfooter"
import {restaurantID} from './translator/id'
import { riviereTrad } from './translator/la_riviere/riviere'

// ICONS
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { HiLockClosed } from "react-icons/hi";



// CUT IMAGES

import Salade from "../assets/pictures/cuts/salade.png"
import Planche from "../assets/pictures/cuts/planche_poivrier_cut.png"

// LOGOS
import LogoJardin from "../assets/pictures/logos/logo_jardin_sans_bg.png";
import LogoIlePlateNobg from "../assets/pictures/logos/logo_ile_plate_nobg.png"
import LogoPlage from '../assets/pictures/logos/logo_plage_sans_bg.png'
import LogoRiviere from "../assets/pictures/logos/logo_riviere_no_bg.png"

// SHOOTING
import Entree from "../assets/pictures/la_riviere/entree_paysage.jpg"
import Stairs from "../assets/pictures/la_riviere/stairs.jpg"
import VueRiviere from "../assets/pictures/la_riviere/vue_riviere.jpg"
import LangoustePasta from "../assets/pictures/le_jardin/shooting/langouste_pasta2_portrait.jpg"
import LangousteMiddle from "../assets/pictures/le_jardin/shooting/langouste_middle_portrait.jpg"
import RisottePortrait from "../assets/pictures/le_jardin/shooting/risotto_portrait.jpg"

import Camping from "../assets/pictures/la_riviere/camping.jpg"
import Chapito from "../assets/pictures/la_riviere/chapito.jpg"
import UnderTree from "../assets/pictures/la_riviere/under_tree.jpg"
import Parasol from "../assets/pictures/la_riviere/parasol.jpg"
import EntreeDezoom from "../assets/pictures/la_riviere/entree_dezoom.jpg"
import LongTable from "../assets/pictures/la_riviere/long_table.jpg"


function LaRiviere() {
  const language = useLanguageStore((state) => state.language)
  document.title='Le POIVRIER - La Rivière';
  document.head.querySelector("[id~=page-description][content]").content="Restaurant on the river, French cuisine , Italian cuisine. Visit us in  Tamarin.";
  return (
    <div className="page2_container">
        {/* <NavbarPlage/> */}
        <div className='scroll_container'>
            <div className="fixed_bg_riviere"> </div>
            <div className="home_logo_container">
                <img className="home_logo" src={LogoRiviere}/>
            </div>

            <div className="jardin_grid_container">
                <div className="grid_jardin_left_side">
                    <div className="title riviere_title">Bienvenue à La Rivière</div>
                    <div className="image_batch_container">
                        <p>{riviereTrad[language].paragraphe1}</p>
                        <img src={Entree} alt="" className='landscape_image'/>
                    </div>
                    <div className="image_batch_container">
                        <div className="batch_title riviere_batch_title">{riviereTrad[language].titre1}</div>
                        <p>{riviereTrad[language].paragraphe2}
                        </p>
                        <img src={LangoustePasta} alt="" className='portrait_image'/>
                        <img src={RisottePortrait} alt="" className='portrait_image move1'/>
                        <img src={LangousteMiddle} alt="" className='portrait_image move2'/>
                    </div>
                </div>
                <div className="grid_jardin_right_side">
                    <div className="image_batch_container">
                        <img src={VueRiviere} className='landscape_image' alt="" />
                        <div className="batch_title riviere_batch_title">{riviereTrad[language].titre2}</div>
                        <p>{riviereTrad[language].paragraphe3}
                        </p>
                    </div>
                    <div className="image_batch_container">
                        <img src={Stairs} alt="" className='landscape_image' />
                        <div className="batch_title riviere_batch_title">{riviereTrad[language].titre3}</div>
                        <p>{riviereTrad[language].paragraphe4}</p>
                    </div>
                    
                    <div className="image_batch_container">
                        
                    </div>
                </div>
            </div>
            <div className="content3">
                <h2 className='contact_h2'>{tradJardin[language].reservation}</h2>
                <div className='reservation_btn_riviere'>{tradJardin[language].bookBtn}</div>
                <h1>{restaurantID[language].rivierePhone}</h1>
            </div>
            {/* CONTACT */}
            <section id="contact_lariviere" className="contact_page_container">
                <div className="contact_post1">
                    <div className="left_container">
                        <div className="text_container">
                            <h2>Le Poivrier</h2>
                            <h3 className='riviere_dark_text_color'>{restaurantID[language].titreRiviere}</h3>
                            <p className='contact_adress'>{restaurantID[language].riviereAdress}</p>
                            <p className="h3_title_bold riviere_light_text_color">{restaurantID[language].rivierePhone}</p>
                        </div>
                    </div>
                    <div className="right_container">
                    <iframe className="contact_gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4920.1762976133405!2d57.3802691!3d-20.329942100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c43c45ec99c6d%3A0x4801d6daa7b63303!2sLe%20Poivrier%20the%20river!5e1!3m2!1sen!2smu!4v1685074781733!5m2!1sen!2smu" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    
                    </div>
                </div>
                <div className="contact_post2">
                    <div className="text_container">
                        <h2>{contactLeJardin[language].contactUs}</h2>
                        <hr className='divider_lejardin'/>
                        <p>{contactLeJardin[language].question}</p>
                        <p>{contactLeJardin[language].fillOut}</p>
                        <p><span>{contactLeJardin[language].noReservation}</span></p>
                    </div>
                    <div className="contact_form_container">
                    <ContactForm prop="riviere"/>
                    </div>
                    
                </div>
                <div className="contact_else">
                    <h2>{vocab[language].else}</h2>
                    <hr className='divider_lejardin'/>
                    <div className="contact_else_container">
                        <HashLink to="/ile_plate#contact_ileplate" className="contact_cata" smooth>
                            <img src={LogoIlePlateNobg} alt=""/>
                        </HashLink>
                       <HashLink to="/laplage#contact_laplage" className="contact_plage" smooth>
                            <img src={LogoPlage} alt=""/>
                       </HashLink>
                       <HashLink to="/lejardin#contact_lejardin" className="contact_jardin" smooth>
                            <img src={LogoJardin} alt=""/>
                       </HashLink>
                       
                    </div>
                </div>
                <div className="contact_post3">
                    <div className="contact_la_riviere_bg"></div>
                    <img src={Planche} alt="" className='image_planche' />
                    <div className="contact_post3_left">
                        <div className="text_container">
                            <h2>{contactLeJardin[language].practicalInfo}</h2>
                            <hr className='divider_riviere'/>
                            <div className="practical_infos">
                                <h3>{contactLeJardin[language].cuisine}</h3>
                                    <p>{contactLeJardin[language].cuisineDescription} <br></br> {contactLeJardin[language].cuisineDescription2}</p>
                                    <h3>{contactLeJardin[language].meansPayment}</h3>
                                    <p>{contactLeJardin[language].payments}</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact_openings_riviere">
                            <h2>{contactLeJardin[language].openings}</h2>
                            <hr className='divider_riviere'/>
                            
                            <div className="day_wrap">
                                <h5 className="day">
                                    <span>{contactLeJardin[language].tuesday}</span>
                                    <span> - </span>
                                    <span>{contactLeJardin[language].saturday}</span>
                                </h5>
                                <p className="hours">
                                    <span className="hour">10h - 15h</span>
                                    <span className="hours_separator">•</span>
                                    <span className="hour">18h - 23h</span>
                                </p>
                            </div>
                            <div className="day_wrap">
                                <h5 className="day">
                                    <span>{contactLeJardin[language].sunday}</span>
                                    <span>-</span>
                                    <span>{contactLeJardin[language].monday}</span>
                                </h5>
                                <p className="hours">
                                    <HiLockClosed className="icon_fermé_riviere" size={20}/>
                                    {contactLeJardin[language].closed}
                                </p>
                            </div>
                            
                        </div>
                    
            </div>

            <div className="mini_gallery_jardin">
                <div className="mini_gallery_wrap">
                    <img src={UnderTree} alt="" />
                    <img src={EntreeDezoom} alt="" />
                    <img src={LongTable} alt="" />
                    <img src={Chapito} alt="" />
                    <img src={Camping} alt="" />
                    <img src={Parasol} alt="" />
                </div>
            </div>
            </section>

            
            
            <Footer/>
        </div>

    </div>
  )
}

export default LaRiviere
