export const restaurantID = {
    français: {
        titreCata: "Restaurant Le Catamaran",
        titreJardin: "Restaurant Le Jardin",
        titrePlage: "Restaurant La Plage",
        titreIlePlate: "Restaurant L'île Plate",
        plageAdress: 'Azuri Ocean & Golf Village, Roches Noires',
        plageAdressCut: 'Azuri',
        plagePhone: '+230 5824 2062',
        jardinAdress: 'Around the World, Coastal Road, Grand Baie',
        jardinAdressCut: 'Grand Baie',
        jardinPhone: '+230 5702 5956',
        cataPhone: '+230 5824 2080',
        cataAdress: "Ponton Sunset Boulevard, Grand Baie",
        cataAdressCut: "Sunset Boulevard",
        ilePlateAdress: "Governor's House, Ile Plate",
        ilePlateAdressCut: 'Ile Plate', 
        ilePlatePhone: '+230 5824 2080',
        titreRiviere: "Restaurant La Rivière",
        riviereAdress: "Les Barachoirs Estate, Tamarin",
        rivierePhone: "+230 5826 4800"
        
    },
    english : {
        titreCata: "Le Catamaran Restaurant",
        titreJardin: "The Garden Restaurant",
        titrePlage: "The Beach Restaurant",
        titreIlePlate: "The Flat Island Restaurant",
        
        plageAdress: 'Azuri Ocean & Golf Village, Roches Noires',
        plageAdressCut: 'Azuri',
        plagePhone: '+230 5824 2062',
        jardinAdress: 'Around the World, Coastal Road, Grand Bay',
        jardinAdressCut: 'Grand Baie',
        jardinPhone: '+230 5702 5956',
        cataPhone: '+230 5824 2080',
        cataAdress: "Sunset Boulevard Pontoon, Grand Bay",
        cataAdressCut: "Sunset Boulevard",
        ilePlateAdress: "Governor's House, Flat Island",
        ilePlateAdressCut: 'Flat Island', 
        ilePlatePhone: '+230 5824 2080',
        titreRiviere: "The River Restaurant",
        riviereAdress: "Les Barachoirs Estate, Tamarin",
        rivierePhone: "+230 5826 4800"
    },

    pусский: {
        titreCata: 'ресторан "Le Catamaran"',
        titreJardin: 'Ресторан Ле Жарден',
        titrePlage: 'Ресторан Ла Плаж',
        titreIlePlate: "Ресторан Л'Иль Плат",
        plageAdress: 'Azuri Ocean & Golf Village, Roches Noires',
        plageAdressCut: 'Azuri',
        plagePhone: '+230 5824 2062',
        jardinAdress: 'Around the World, Coastal Road, Grand Baie',
        jardinAdressCut: 'Grand Baie',
        jardinPhone: '+230 5702 5956',
        cataPhone: '+230 5824 2080',
        cataAdress: "понтон Sunset Boulevard, Grand Baie",
        cataAdressCut: "Sunset Boulevard",
        ilePlateAdress: "Governor's House, Ile Plate",
        ilePlateAdressCut: 'Ile Plate', 
        ilePlatePhone: '+230 5824 2080',
        titreRiviere: "Ресторан Ля Ривьер",
        riviereAdress: "Les Barachoirs Estate, Tamarin",
        rivierePhone: "+230 5826 4800"
    }
}