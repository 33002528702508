import React from 'react'

import { Link, NavLink } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"

import { useLanguageStore } from '../subcomponents/LanguageStore';
import { tradFooter, week } from '../translator/le_jardin/tradfooter';
import {restaurantID} from '../translator/id'
import { TradNavbar } from '../translator/tradNavbar';
import { HomeTrad } from '../translator/homeTrad';

import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { AiOutlineMail, AiOutlineInstagram } from "react-icons/ai";
import { FaTripadvisor } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";



function Footer() {
    // LANGUAGE
    const language = useLanguageStore((state) => state.language)
    const setToFrench = useLanguageStore((state) => state.setToFrench)
    const setToEnglish = useLanguageStore((state) => state.setToEnglish)
    const setToRussian = useLanguageStore((state) => state.setToRussian)

    const handleCLickFrench = () => {
      setToFrench()
    }
    const handleCLickEnglish = () => {
      setToEnglish()
    }
    const handleCLickRussian = () => {
      setToRussian()
    }

  return (
    <div className='footer'>
     
        <div className="footer_flex_container">
          <div className="footer_language_container">
                <h3 className="footer_language_title">{language}</h3>
                <div className="langague_flags_container">
                  <div className="flag_container french" onClick={handleCLickFrench}></div>
                  <div className="flag_container english" onClick={handleCLickEnglish}></div>
                  <div className="flag_container russian" onClick={handleCLickRussian}></div>
                </div>
            </div>
          
            <div className="social_media">
              <a className="social_media_link_lariviere" href="https://www.tripadvisor.com/Restaurant_Review-g488103-d12242815-Reviews-Le_Poivrier-Grand_Baie.html" target="_blank" rel="noopener noreferrer"><FaTripadvisor  size={30} className='social_media_icon'/></a>
              <a className="social_media_link_lariviere" href="https://www.instagram.com/lepoivrier_le_jardin/" target="_blank" rel="noopener noreferrer"><AiOutlineInstagram size={30} className='social_media_icon'/></a>
              <a className="social_media_link_lariviere" href="https://www.facebook.com/lePoivrierCafeRestau/" target="_blank" rel="noopener noreferrer"><BsFacebook  size={30} className='social_media_icon'/></a>
            </div>
        </div>
        <div className="links_container">
          <h3>{TradNavbar[language].quickLinks}</h3>
          <ul>
            <HashLink className="quick_link" to="/#accueil" smooth>{TradNavbar[language].home}</HashLink>
            <HashLink className="quick_link" to="/#contact" smooth>{HomeTrad[language].contact}</HashLink>
            <NavLink className="quick_link" to="/gallery">{TradNavbar[language].gallery}</NavLink>
            <NavLink className="quick_link" to="/lejardin">{TradNavbar[language].jardin}</NavLink>
            <NavLink className="quick_link" to="/ile_plate">{TradNavbar[language].ilePlate}</NavLink>
            <NavLink className="quick_link" to="/lariviere">{TradNavbar[language].riviere}</NavLink>
            <NavLink className="quick_link" to="/laplage">{TradNavbar[language].plage}</NavLink>
          </ul>
        </div>
            
      
    </div>
  )
}

export default Footer

