import React from 'react'
import { NavLink } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"

import ContactForm from './subcomponents/ContactForm'
import FooterPlage from './layout/FooterPlage'

// LANGUAGE
import { useLanguageStore } from './subcomponents/LanguageStore';
import {vocab} from './translator/vocab'
import {contactLeJardin} from "./translator/le_jardin/contactLeJardin"
import {restaurantID} from './translator/id'
import {tradJardin} from './translator/le_jardin/tradLeJardin'
import {TradNavbar} from './translator/tradNavbar'


import {PlageTrad} from "./translator/la_plage/tradPlage"

// LOGOS
import LogoJardin from "../assets/pictures/logos/logo_jardin_sans_bg.png";
import LogoIlePlateNobg from "../assets/pictures/logos/logo_ile_plate_nobg.png"
import LogoPlage from '../assets/pictures/logos/logo_plage_sans_bg.png'
import LogoRiviere from "../assets/pictures/logos/logo_riviere_no_bg.png"

// ICONS
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { HiLockClosed } from "react-icons/hi";

// CUT IMAGES
import IleFlottante from "../assets/pictures/cuts/ile_flottante.png"


// SHOOTING
///paysage
import Plage from "../assets/pictures/la_plage/la_plage.jpg"
import FauteuilsPaysage from '../assets/pictures/la_plage/fauteuils_paysage.jpg'
import DeckPaysage from "../assets/pictures/la_plage/deck_paysage.jpg"
import BehindTreePaysage from "../assets/pictures/la_plage/behind_tree_paysage.jpg"
import TerrasseZoom from "../assets/pictures/la_plage/plage_terrasse_zoom.jpg"
import BeachView from '../assets/pictures/la_plage/plage_beach_view.jpg'
import RayuresPaysage from '../assets/pictures/la_plage/rayures_paysage.jpg'
///portrait
import Totem from "../assets/pictures/la_plage/totem.jpg"
import Rayures from "../assets/pictures/la_plage/rayures.jpg"
import WhiteTable from "../assets/pictures/la_plage/white_table.jpg"

function LaPlage() {
  const language = useLanguageStore((state) => state.language)
  document.title='Le POIVRIER - La Plage';
  document.head.querySelector("[id~=page-description][content]").content="Restaurant on the beach, French cuisine , Italian cuisine. Visit us in  Azuri Ocean & Golf Village, Roches Noires, M4 Rivière du Rempart";
  return (
    <div className="page2_container">
        {/* <NavbarPlage/> */}
        <div className='scroll_container'>
            <div className="fixed_bg_plage"> </div>
            <div className="home_logo_container">
                    <img className="home_logo" src={LogoPlage}/>
                
            </div>
            <div className="jardin_grid_container">
                <div className="grid_jardin_left_side">
                    <div className="title plage_title">Bienvenue à La Plage</div>
                    <div className="image_batch_container">
                        <p>{PlageTrad[language].paragraphe1}</p>
                        <img src={BeachView} alt="" className='landscape_image'/>
                    </div>
                    <div className="image_batch_container">
                        <div className="batch_title plage_batch_title">{PlageTrad[language].titre1}</div>
                        <p>{PlageTrad[language].paragraphe2}</p>
                        <img src={Totem} alt="" className='portrait_image'/>
                        <img src={Rayures} alt="" className='portrait_image move1'/>
                        <img src={WhiteTable} alt="" className='portrait_image move2'/>
                    </div>
                </div>
                <div className="grid_jardin_right_side">
                    <div className="image_batch_container">
                        <img src={DeckPaysage} className='landscape_image' alt="" />
                        <div className="batch_title plage_batch_title">{PlageTrad[language].titre2}</div>
                        <p>{PlageTrad[language].paragraphe3}</p>
                    </div>
                    <div className="image_batch_container">
                        <img src={Plage} alt="" className='landscape_image' />
                        <div className="batch_title plage_batch_title">{PlageTrad[language].titre3}</div>
                        <p>{PlageTrad[language].paragraphe4}</p>
                    </div>
                    
                    <div className="image_batch_container">
                        
                    </div>
                </div>
            </div>
            <div className="content3">
                <h2 className='contact_h2'>{tradJardin[language].reservation}</h2>
                <div className='reservation_btn_plage'>{tradJardin[language].bookBtn}</div>
                <h1>{restaurantID[language].plagePhone}</h1>
            </div>

              {/* CONTACT */}
      <section className="contact_page_container" id="contact_laplage">
      <div className="contact_post1">
                <div className="left_container">
                    <div className="text_container">
                        <h2>Le Poivrier</h2>
                        <h3 className='laplage_ocean'>{restaurantID[language].titrePlage}</h3>
                        <p className='contact_adress'>{restaurantID[language].plageAdress}</p>
                        <p className="contact_phone_laplage">{restaurantID[language].plagePhone}</p>
                    </div>
                </div>
                <div className="right_container">
                
                
                {/* <iframe className="laplage_gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1194.0928270758095!2d57.709191716262104!3d-20.09484160993444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217cff930eb0222b%3A0xb01cdf2d2275d1f9!2sLe%20Poivrier%20-%20La%20Plage!5e1!3m2!1sen!2smu!4v1667531523518!5m2!1sen!2smu" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe className="laplage_gmap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19086.00205196561!2d57.7091714!3d-20.094578!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xff125f0bdd012b47!2sLe%20Poivrier%20-%20La%20Plage!5e1!3m2!1sen!2smu!4v1669782429452!5m2!1sen!2smu" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
                </div>
            </div>
            <div className="contact_post2">
                <div className="text_container">
                    <h2>{contactLeJardin[language].contactUs}</h2>
                    <hr className='divider_laplage'/>
                    <p>{contactLeJardin[language].question}</p>
                    <p>{contactLeJardin[language].fillOut}</p>
                    <p><span>{contactLeJardin[language].noReservation}</span></p>

                </div>
                <div className="contact_form_container">
                <ContactForm prop="plage" />
                </div>
                
            </div>
            <div className="contact_else">
                <h2>{vocab[language].else}</h2>
                <hr className='divider_laplage'/>
                <div className="contact_else_container">
                  <HashLink to='/lariviere#contact_lariviere' className="contact_riviere" smooth><img src={LogoRiviere} alt=""/></HashLink>
                    <HashLink to='/ile_plate#contact_ileplate' className="contact_cata" smooth><img src={LogoIlePlateNobg} alt=""/></HashLink>

                    <HashLink to='/lejardin#contact_lejardin' className="contact_jardin" smooth><img src={LogoJardin} alt=""/></HashLink>
   
                </div>
            </div>
            <div className="contact_post3">
            <div className="contact_plage_bg"></div>
                <img src={IleFlottante} alt="" className='image_cut_contact_plage'/>
                <div className="contact_post3_left">
                    <div className="text_container">
                        <h2>{contactLeJardin[language].practicalInfo}</h2>
                        <hr className='divider_laplage'/>
                        <div className="practical_infos">
                        <h3>{contactLeJardin[language].cuisine}</h3>
                            <p>{contactLeJardin[language].cuisineDescription} <br></br> {contactLeJardin[language].cuisineDescription2}</p>
                            <h3>{contactLeJardin[language].meansPayment}</h3>
                            <p>{contactLeJardin[language].payments}</p>
                        </div>
                    </div>
                </div>
                <div className="contact_openings_plage">
                        <h2>{contactLeJardin[language].openings}</h2>
                        <hr className='divider_laplage'/>
                        <div className="day_wrap">
                            <h5 className="day">
                                <span>{contactLeJardin[language].tuesday}</span>
                                <span> - </span>
                                <span>{contactLeJardin[language].saturday}</span>
                            </h5>
                            <p className="hours">
                                <span className="hour">12h - 15h</span>
                                <span className="hours_separator">•</span>
                                <span className="hour">18h30 - 22h</span>
                            </p>
                        </div>
           
                        <div className="day_wrap">
                            <h5 className="day">
                            <span>{contactLeJardin[language].sunday}</span>
                            </h5>
                            <p className="hours">
                                <span className="hour">12h - 15h</span>
                            </p>
                        </div>
                        <div className="day_wrap">
                            <h5 className="day">
                                
                                <span>{contactLeJardin[language].monday}</span>
                                
                            </h5>
                            <p className="hours">
                                <HiLockClosed className="icon_fermé_plage" size={20}/>
                                {contactLeJardin[language].closed}
                            </p>
                        </div>
                        
                    </div>
                
            </div>
            <div className="mini_gallery_jardin">
                <div className="mini_gallery_wrap">
                    <img src={FauteuilsPaysage} alt="" />
                    <img src={DeckPaysage} alt="" />
                    <img src={TerrasseZoom} alt="" />
                    <img src={BehindTreePaysage} alt="" />
                    <img src={BeachView} alt="" />
                    <img src={RayuresPaysage} alt="" />
                </div>
            </div>
      </section>

        <FooterPlage/>
        </div>
      
    </div>
  )
}

export default LaPlage