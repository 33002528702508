export const HomeTrad = {
    français: {
        jardinp1: "Le Poivrier Le Jardin vous transporte dans un cadre paisible et verdoyant, où vous pourrez savourer une cuisine raffinée dans une ambiance détendue.",
        jardinp2: "Niché au cœur d'un magnifique jardin luxuriant, ce restaurant offre une expérience culinaire unique.",
        jardinp3: "Rendez nous visite à Grand Baie.",
        ilep1: "Bienvenue au Poivrier L'île Plate, où la cuisine de première qualité rencontre l'histoire fascinante de l'île.",
        ilep2: "Venez découvrir notre restaurant pittoresque où vous pourrez déguster une cuisine française innovante et élégante tout en admirant les ruines de l'époque des pirates.",
        ilep3: "Que vous recherchiez une escapade romantique ou une occasion spéciale, Le Poivrier L'Ile Plate vous offre une expérience hors du commun.",
        rivierep1:"Le Poivrier La Rivière est niché au bord d'une rivière paisible, offrant un cadre charmant et serein pour profiter de notre cuisine raffinée.",
        rivierep2: "Le Poivrier La Rivière vous invite à vous détendre et vous régaler, entourés par la nature luxuriante de Tamarin.",
        plagep1: "Le Poivrier La Plage vous transporte dans un cadre idyllique en bord de mer, où vous pourrez déguster notre cuisine raffinée tout en admirant la vue imprenable sur l'océan.",
        plagep2: "Notre restaurant de plage offre une expérience gastronomique inoubliable.",
        plagep3: "Laissez-vous emporter par la brise marine et les saveurs délicates de nos plats préparés avec passion.",
        voirPlus: "Voir plus",
        contact: "contact",
    },
    english: {
        jardinP1: "Le Poivrier The Garden transports you to a peaceful and green setting, where you can savor refined cuisine in a relaxed ambiance.",
        jardinp2: "Nestled in the heart of a beautiful lush garden, this restaurant offers a unique culinary experience.",
        jardinp3: "Visit us in Grand Baie.",
        ilep1: "Welcome to Le Poivrier Flat Island, where fine cuisine meets the fascinating history of the island.",
        ilep2: "Come and discover our picturesque restaurant where you can indulge in innovative and elegant French cuisine while admiring the ruins from the pirate era.",
        ilep3: "Whether you're seeking a romantic getaway or a special occasion, Le Poivrier L'Ile Plate offers you an extraordinary experience.",
        rivierep1:"Le Poivrier The River is nestled along a peaceful river, providing a charming and serene setting to indulge in our refined cuisine.",
        rivierep2: "Le Poivrier The River invites you to relax and indulge, surrounded by the lush nature of Tamarin.",
        plagep1: "Le Poivrier The Beach transports you to an idyllic beachfront setting, where you can savor our refined cuisine while enjoying breathtaking views of the ocean.",
        plagep2: "Our beachfront restaurant offers an unforgettable gastronomic experience.",
        plagep3: "Let yourself be carried away by the sea breeze and the delicate flavors of our dishes prepared with passion.",
        voirPlus: "See more",
        contact: "contact",
    },
    pусский: {
        jardinP1: "Ресторан Ле Пуаврье Ле Жардин перенесет вас в спокойную и зеленую обстановку, где вы сможете насладиться изысканной кухней в расслабленной атмосфере.",
        jardinp2: "Уютно расположенный в сердце прекрасного пышного сада, этот ресторан предлагает уникальный кулинарный опыт.",
        jardinp3: "Приходите к нам в Гранд-Баи.",
        ilep1: "Добро пожаловать в ресторан Le Poivrier L'île Plate, где высококачественная кухня сочетается с увлекательной историей острова.",
        ilep2: "Приходите и откройте для себя наш живописный ресторан, где вы сможете насладиться инновационной и элегантной французской кухней, на фоне руин из пиратской эпохи.",
        ilep3: "Независимо от того, ищете ли вы романтическую поездку или особый случай, Le Poivrier L'Ile Plate предлагает вам непревзойденный опыт.",
        rivierep1:'Le Poivrier "Река" расположен у самой спокойной реки, предлагая очаровательную и спокойную атмосферу, чтобы насладиться нашей изысканной кухней.',
        rivierep2: 'Le Poivrier "Река" vous invite à vous détendre et vous régaler, entourés par la nature luxuriante de Tamarin.',
        plagep1: "Le Poivrier Ла Плаж перенесет вас в идиллическую атмосферу на берегу моря, где вы сможете насладиться изысканной кухней, наслаждаясь впечатляющим видом на океан.",
        plagep2: "Наш ресторан на пляже предлагает незабываемый гастрономический опыт.",
        plagep3: "Позвольте себе унестись морским бризом и изысканными вкусами наших блюд, приготовленных с любовью.",
        voirPlus: "Смотреть больше",
        contact: "контакты",
    }
}