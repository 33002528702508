export const tradIlePlate = {
    français: {
        // /ile_plate
        titre1: "Bienvenue à l'Ile Plate",
        description1: "Bienvenue au Poivrier L'île Plate, où la cuisine de première qualité rencontre l'histoire fascinante de l'île. Venez découvrir notre restaurant pittoresque où vous pourrez déguster une cuisine française innovante et élégante tout en admirant les ruines de l'époque des pirates.",
        sous_titre1: "Nature Incroyable",
        description2: "En plus de la beauté naturelle, l'île Plate abrite une réserve naturelle gérée par des gardiens dévoués de la faune et de la flore locale. Vous pouvez y découvrir des espèces endémiques de plantes et d'animaux. Et si vous êtes assez chanceux, vous pourriez même voir des tortues de mer qui pondent leurs œufs sur les plages.",
        sous_titre2: "Un air de St Tropez",
        description3: "Vivez une escapade luxueuse ultime. Imprégnez-vous de l'ambiance de St Tropez avec une touche tropicale, accompagnée de musique animée qui crée une atmosphère propice à une expérience culinaire inoubliable.",
        sous_titre3: "Lieu Mythique",
        description4: "L'île Plate a également une histoire de pirates ! C'était une base populaire pour les flibustiers dans les années 1600. Vous pouvez encore voir les ruines des fortifications construites pour se protéger des attaques ennemies. Qui sait, peut-être que vous trouverez même un trésor caché sous le sable ou une carte au trésor cachée dans une bouteille de rhum !",
        // /ile_plate/contact
        para1: "Vous souhaitez passer une soirée inoubliable ? Pensez à réserver ",
        strong1: "48 heures à l'avance",
        para1Suite: 'pour ne pas manquer cette occasion.',
        para2: "Pour réserver, n'hésitez pas à faire votre demande directement par téléphone. Pour officialiser la réservation, un lien vous sera envoyé pour le paiement, 8500rs par personne.",
        para3: "Nous vous attendrons à ",
        strong3:"8h30 sur le ponton du Sunset Boulevard",
        para4: "Pour des raisons de sécurité, veuillez noter que nous ne pouvons accueillir que des invités âgés de ",
        strong4: "12 ans et plus",
        para4Suite: ". Nous nous excusons pour tout inconvénient que cela pourrait causer et espérons avoir le plaisir de vous accueillir bientôt.",
        para5:"Vous avez un imprévu et devez annuler la soirée ? Pas d'inquiétude, la réservation est ",
        strong5: "remboursable jusqu'à 48 heures avant le départ.",
        menu: "Nous proposons un délicieux menu pour votre déjeuner, avec en entrée une ballotine de la mer et deux choix de plats principaux : babonne de St. Brandon ou filet de boeuf. Les accompagnements comprennent une salade, de la mousse de carotte et de l'écrasé de pomme de terre, tandis que le dessert est une délicieuse crème brûlée à la vanille de Madagascar. Veuillez noter que le menu peut varier en fonction de l'arrivage et de la disponibilité des ingrédients.",
        menuTitre: "MENU",
        practicalInfo: "Rdv 8h30 sur le ponton du Sunset Bd., prévoir maillot de bain, crème solaire, serviette, masque,  palmes et chaussures d'eau.",
        cuisineDescription: "Cuisine française et italienne",
        cuisineDescription2: "poissons locaux",
        // PRIVATISER
        privatiser: "PRIVATISER",
        priva1: "L'île est un lieu exceptionnel pour organiser des anniversaires, des mariages ou tout autre événement spécial. Il est possible de ",
        priva1strong1: "privatiser l'île ",
        priva1Suite: "pour votre événement et de créer une expérience sur mesure pour vous et vos invités.",
        priva2: "Si vous souhaitez simplement profiter d'une croisière tranquille d'environ une heure, il est également possible de privatiser ",
        priva2strong1: "le transport.",
        priva3: "La privatisation est possible sur demande et sous réserve de disponibilité. Les tarifs sont également disponibles sur demande.",
        priva4: "Quelle que soit votre choix, notre équipe sera heureuse de vous aider à organiser un événement inoubliable sur l'île.",
    },
    english: {
        // /ile_plate
        titre1: "Bienvenue à l'Ile Plate",
        description1: "Welcome to Le Poivrier L'île Plate, where first-class cuisine meets the fascinating history of the island. Come discover our picturesque restaurant where you can indulge in innovative and elegant French cuisine while admiring the ruins from the pirate era.",
        sous_titre1: "Incredible Nature",
        description2: "In addition to natural beauty, Flat Island is home to a nature reserve managed by dedicated guardians of the local fauna and flora. Here you can discover endemic species of plants and animals. And if you're lucky enough, you might even spot sea turtles laying their eggs on the beaches.",
        sous_titre2: "In the style of St Tropez",
        description3: "Experience the ultimate luxurious getaway. Immerse yourself in the ambiance of St Tropez with a tropical twist, accompanied by lively music that sets the mood for an unforgettable culinary experience.",
        sous_titre3: "Mythical Place",
        description4: "Flat Island also has a history of pirates! It was a popular base for buccaneers in the 1600s. You can still see the ruins of fortifications built to protect against enemy attacks. Who knows, maybe you'll even find a hidden treasure under the sand or a treasure map hidden in a bottle of rum!",
        // /ile_plate/contact
        para1:"Do you want to spend an unforgettable evening? Remember to book",
        strong1:"48 hours in advance",
        para1suite: "so you don't miss this opportunity.",
        para2: "Please do not hesitate to contact us directly by phone to book your evening. To formalize the reservation, a link will be sent to you for the payment, 8500rs per person.",
        para3: "We will be waiting for you at ",
        strong3:"8:30 am on the Sunset Boulevard pontoon.",
        para4: "For safety reasons, please note that we can only accommodate guests ",
        strong4: "aged 12 and over",
        para4Suite: ". We apologize for any inconvenience this may cause and look forward to welcoming you soon.",
        para5: "Do you have an unexpected event and need to cancel the evening? Don't worry, the reservation is ",
        strong5: "refundable up to 48 hours before departure.",
        menuTitre: "MENU",
        menu: "We offer a delicious menu for your lunch, featuring an appetizer of ballotine of the sea and two main course choices: babonne de St. Brandon or beef fillet. Accompaniments include a salad, carrot mousse, and mashed potatoes, while dessert is a delightful Madagascar vanilla crème brûlée. Please note that the menu may vary depending on ingredient availability.",
        practicalInfo: "Meeting at 8:30am on the Sunset Bd. dock, please bring swimwear, sunscreen, a towel, a mask, fins and water shoes.",
        cuisineDescription :"French and Italian cuisine",
        cuisineDescription2: "local fishes",
        // PRIVATISER
        privatiser: "PRIVATIZING",
        priva1: "The island is an exceptional venue to host birthdays, weddings, or any other special events. It is possible to ",
        priva1strong1: "privatize the island ",
        priva1Suite: "for your event and create a tailored experience for you and your guests.",
        priva2: "If you simply want to enjoy a peaceful one-hour cruise, it is also possible to privatize ",
        priva2strong1: "the transportation.",
        priva3: "Privatization is possible upon request and subject to availability. Rates are also available upon request.",
        priva4: "Whatever your choice may be, our team will be happy to assist you in organizing an unforgettable event on the island.",
    },
    pусский: {
        // /ile_plate
        titre1: "Bienvenue à l'Ile Plate",
        description1: "Добро пожаловать на остров Плат и в ресторан Le Poivrier, где первоклассная кухня сочетается с увлекательной историей острова. Приходите открыть для себя наш живописный ресторан, где вы сможете насладиться инновационной и изысканной французской кухней, на фоне руин из пиратской эпохи.",
        sous_titre1: "Невероятная природа",
        description2: "Помимо природной красоты, остров Плат является домом для природного заповедника, управляемого преданными хранителями местной фауны и флоры. Здесь вы можете открыть для себя эндемические виды растений и животных. И если вы достаточно удачливы, то можете увидеть морских черепах, которые откладывают свои яйца на пляжах.",
        sous_titre2: "Атмосфера Сен-Тропе",
        description3: "Почувствуйте настоящий роскошный отдых. Погрузитесь в атмосферу Сен-Тропе с тропическим уклоном, сопровождаемую живой музыкой, которая создает настроение для незабываемого кулинарного опыта.",
        sous_titre3: "Мифическое место",
        description4: "На острове Плат также есть история пиратов! Это была популярная база для пиратов в 1600-х годах. Вы все еще можете увидеть руины укреплений, построенных для защиты от нападений врагов. Кто знает, возможно, вы даже найдете скрытый сокровища под песком или карту сокровищ, скрытую в бутылке рома!",
        // /ile_plate/contact
        para1: "Вы желаете провести незабываемый вечер? Позаботьтесь о резервации",					
        strong1: "За 48 часа", 
        para1suite: "чтоб не упустить случай.",
        para2: "Чтобы забронировать, не стесняйтесь обращаться непосредственно по телефону. Для подтверждения бронирования мы вышлем вам ссылку на оплату, 8500rs на человека.",
        para3: "Мы вас ждём в ",
        strong3: "8.30 на пирсе Sunset Boulevard.",
        para4:"В целях безопасности обратите внимание, что мы можем принимать только гостей в возрасте от ",
        strong4: "12 лет и старше",
        para4Suite: ". Приносим извинения за возможные неудобства и надеемся скоро вас приветствовать.",
        para5: "У вас случилась непредвиденная ситуация, и вам нужно отменить вечеринку? Не беспокойтесь, ",
        strong5: "бронирование можно вернуть до 48 часов до дня Х.",
        menu :"Мы предлагаем вкусное меню на обед, в которое входят закуска из баллотина морепродуктов и два основных блюда на выбор: бабонн из Сен-Брандона или говяжий филе. К сопутствующим блюдам относятся салат, мусс из моркови и картофельное пюре, а десерт - великолепный крем-брюле с ванилью из Мадагаскара. Пожалуйста, обратите внимание, что меню может меняться в зависимости от поставок продуктов.",
        menuTitre: "Меню",
        practicalInfo: "Встреча в 8:30 утра на причале Sunset Blvd. Пожалуйста, не забудьте взять купальник, солнцезащитный крем, полотенце, маску, ласты и водные туфли.",
        cuisineDescription :"Французская и итальянская кухня",
        cuisineDescription2: "Местные рыбы",
        // PRIVATISER
        privatiser: "ПРИВАТИЗАЦИЯ",
        priva1: "Остров является исключительным местом для проведения дней рождений, свадеб или любого другого особого события. Вы можете ",
        priva1strong1: "приватизировать остров ",
        priva1Suite: "для вашего мероприятия и создать индивидуальный опыт для вас и ваших гостей.",
        priva2: "Если вы просто хотите насладиться спокойным круизом длительностью около часа, вы также можете забронировать ",
        priva2strong1: "транспорт для частного использования.",
        priva3: "Приватизация возможна по запросу и при наличии свободных мест. Цены также доступны по запросу.",
        priva4: "Каким бы ни был ваш выбор, наша команда с радостью поможет вам организовать незабываемое мероприятие на острове.",
    }
}
