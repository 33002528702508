export const vocab = {
    français: {
        seeMore: "voir plus",
        comingSoon: "Disponible dès Novembre",
        cataByNight: "la baie de nuit",
        plateGabriel: 'île plate / îlot gabriel',
        bookNow: "Réserver Maintenant",
        seeOffers: 'Voir les offres',
        construction:'En construction',
        comeBack:'Revenez bientôt',
        else: "contacter un autre restaurant",
    },
    english: {
        seeMore: "see more",
        comingSoon: "Available in November",
        cataByNight: "the bay by night",
        plateGabriel: 'flat island / gabriel island',
        bookNow: "Book Now",
        seeOffers: 'See offers',
        construction:'Under construction',
        comeBack:'Come back soon',
        else: "contact another restaurant",
    },

    pусский: {
        seeMore: "узнать больше",
        comingSoon: "доступно с ноября",
        cataByNight: "Вечерний залив",
        plateGabriel: "плоский остов \ остров Габриэля",
        bookNow: "забронировать сейчас",
        seeOffers: "просмотреть предложения",
        construction: "планируется открытие",
        comeBack: "до новых встречь",
        else: "связаться с другим рестораном",
    }

}