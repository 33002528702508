import React from 'react'
import { NavLink } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"

import NavbarJardin from './layout/old/NavbarJardin'
import FooterJardin from './layout/FooterJardin'
import ContactForm from './subcomponents/ContactForm'



// LANGUAGE
import { useLanguageStore } from './subcomponents/LanguageStore';
import {tradJardin} from './translator/le_jardin/tradLeJardin'

import {vocab} from './translator/vocab'
import {contactLeJardin} from "./translator/le_jardin/contactLeJardin"
import {tradFooter, week} from "./translator/le_jardin/tradfooter"
import {restaurantID} from './translator/id'

// ICONS
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { HiLockClosed } from "react-icons/hi";



// CUT IMAGES
import Planche from '../assets/pictures/cuts/planche_poivrier.png'
import PoivrierRouge from '../assets/pictures/cuts/le_poivrier_rouge_cut_droit.png'
import PoivrierMarron from '../assets/pictures/cuts/poivrier_marron_cut_droit.png'
import Salade from "../assets/pictures/cuts/salade.png"

// LOGOS
import JardinLogo from "../assets/pictures/logos/logo_jardin_sans_bg.png";
import LogoIlePlateNobg from "../assets/pictures/logos/logo_ile_plate_nobg.png"
import LogoPlageNobg from '../assets/pictures/logos/logo_plage_sans_bg.png'
import LogoRiviere from "../assets/pictures/logos/logo_riviere_no_bg.png"

// SHOOTING
import Entree from "../assets/pictures/le_jardin/shooting/entree_portrait.jpg"
import Carpaccio1 from "../assets/pictures/le_jardin/shooting/carpaccio_zoom.jpg"
import Desserts from "../assets/pictures/le_jardin/shooting/desserts.jpg"
import Mojito from "../assets/pictures/le_jardin/shooting/mojito.jpg"
import Cocktail1 from "../assets/pictures/le_jardin/shooting/cocktail1.jpg"
import FreshJuice from "../assets/pictures/le_jardin/shooting/fresh_juice.jpg"
import TableNight from "../assets/pictures/le_jardin/shooting/table_nuit.jpg"
import AlleeBas from "../assets/pictures/le_jardin/shooting/allée_bas.jpg"
import TableZoom from "../assets/pictures/le_jardin/shooting/table_zoom.jpg"
import Terrasse from "../assets/pictures/le_jardin/shooting/terrasse.jpg"
import Bar from "../assets/pictures/le_jardin/shooting/bar.jpg"
import VoyageurJour from "../assets/pictures/le_jardin/shooting/voyageur_jour.jpg"


function LeJardin() {

    const language = useLanguageStore((state) => state.language)
    document.title='Le POIVRIER - Le Jardin';
    document.head.querySelector("[id~=page-description][content]").content="French cuisine, Italian cuisine, real sushi chef in a beautiful garden. Visit us in Grand Bay.";
  return (
    <div className='page2_container'>
        {/* <NavbarJardin/> */}
        <div className='scroll_container'>
            <div className="fixed_bg_jardin"> </div>
            <div className="scroll_header">
                {/* <h2>Le POIVRIER <div>- Le Jardin -</div></h2>
                <h4>{tradJardin[language].restaurant}</h4> */}
                <img className="landing_logo" src={JardinLogo}/>
            </div>
            <div className="jardin_grid_container">
                <div className="grid_jardin_left_side">
                    <div className="title jardin_title">Bienvenue au Jardin</div>
                    <div className="image_batch_container">
                        <p>{tradJardin[language].ede}</p>
                        <img src={Entree} alt="" className='landscape_image'/>
                    </div>
                    <div className="image_batch_container">
                        <div className="batch_title jardin_batch_title">Drink</div>
                        <p>{tradJardin[language].drink1} 
                        {tradJardin[language].drink2}
                        {tradJardin[language].drink3}
                        </p>
                        <img src={FreshJuice} alt="" className='portrait_image'/>
                        <img src={Mojito} alt="" className='portrait_image move1'/>
                        <img src={Cocktail1} alt="" className='portrait_image move2'/>
                    </div>
                </div>
                <div className="grid_jardin_right_side">
                    <div className="image_batch_container">
                        <img src={Carpaccio1} className='landscape_image' alt="" />
                        <div className="batch_title jardin_batch_title">Eat</div>
                        <p>{tradJardin[language].eat1}
                        {tradJardin[language].eat2}</p>
                    </div>
                    <div className="image_batch_container">
                        <img src={Desserts} alt="" className='landscape_image' />
                        <div className="batch_title jardin_batch_title">Enjoy</div>
                        <p>{tradJardin[language].enjoy1} {tradJardin[language].enjoy3}</p>
                    </div>
                    
                    <div className="image_batch_container">
                        
                    </div>
                </div>
            </div>
            <div className="content3">
                <h2 className='contact_h2'>{tradJardin[language].reservation}</h2>
                <div className='reservation_btn_jardin'>{tradJardin[language].bookBtn}</div>
                <h1>{tradJardin[language].phone}</h1>
            </div>
            {/* CONTACT */}
            <section id="contact_lejardin" className="contact_page_container">
                <div className="contact_post1">
                    <div className="left_container">
                        <div className="text_container">
                            <h2>Le Poivrier</h2>
                            <h3 className='lejardin_color1'>{restaurantID[language].titreJardin}</h3>
                            <p className='contact_adress'>{restaurantID[language].jardinAdress}</p>
                            <p className="contact_phone">{restaurantID[language].jardinPhone}</p>
                        </div>
                    </div>
                    <div className="right_container">
                    <iframe className="contact_gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4935.425837250637!2d57.580304214915614!3d-20.00153748656379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217dabfa8cb02f2d%3A0x66ad9a4e8486312f!2sLe%20Poivrier!5e1!3m2!1sen!2smu!4v1665989601695!5m2!1sen!2smu" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>
                <div className="contact_post2">
                    <div className="text_container">
                        <h2>{contactLeJardin[language].contactUs}</h2>
                        <hr className='divider_lejardin'/>
                        <p>{contactLeJardin[language].question}</p>
                        <p>{contactLeJardin[language].fillOut}</p>
                        <p><span>{contactLeJardin[language].noReservation}</span></p>
                    </div>
                    <div className="contact_form_container">
                    <ContactForm prop="jardin"/>
                    </div>
                    
                </div>
                <div className="contact_else">
                    <h2>{vocab[language].else}</h2>
                    <hr className='divider_lejardin'/>
                    <div className="contact_else_container">
                        <HashLink to="/ile_plate#contact_ileplate" className="contact_cata" smooth>
                            <img src={LogoIlePlateNobg} alt=""/>
                        </HashLink>
                       <HashLink to="/laplage#contact_laplage" className="contact_plage" smooth>
                            <img src={LogoPlageNobg} alt=""/>
                       </HashLink>
                       <HashLink to="/lariviere#contact_lariviere" className="contact_riviere" smooth>
                            <img src={LogoRiviere} alt=""/>
                       </HashLink>
                       
                    </div>
                </div>
                <div className="contact_post3">
                    <div className="contact_jardin_bg"></div>
                    <img src={Salade} alt="" className='image_salade' />
                    <div className="contact_post3_left">
                        <div className="text_container">
                            <h2>{contactLeJardin[language].practicalInfo}</h2>
                            <hr className='divider'/>
                            <div className="practical_infos">
                                <h3>{contactLeJardin[language].cuisine}</h3>
                                    <p>{contactLeJardin[language].cuisineDescription} <br></br> {contactLeJardin[language].cuisineDescription2}</p>
                                    <h3>{contactLeJardin[language].meansPayment}</h3>
                                    <p>{contactLeJardin[language].payments}</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact_openings">
                            <h2>{contactLeJardin[language].openings}</h2>
                            <hr className='divider'/>
                            <div className="day_wrap">
                                <h5 className="day">
                                    <span>{week[language].tuesday}</span>
                                </h5>
                                <p className="hours">
                                    <span className="hour">12h - 15h</span>
                                </p>
                            </div>
                            <div className="day_wrap">
                                <h5 className="day">
                                    <span>{contactLeJardin[language].wednesday}</span>
                                    <span> - </span>
                                    <span>{contactLeJardin[language].saturday}</span>
                                </h5>
                                <p className="hours">
                                    <span className="hour">12h - 15h</span>
                                    <span className="hours_separator">•</span>
                                    <span className="hour">19h - 22h</span>
                                </p>
                            </div>
                            <div className="day_wrap">
                                <h5 className="day">
                                    <span>{contactLeJardin[language].sunday}</span>
                                    <span>-</span>
                                    <span>{contactLeJardin[language].monday}</span>
                                </h5>
                                <p className="hours">
                                    <HiLockClosed className="closed_icon" size={20}/>
                                    {contactLeJardin[language].closed}
                                </p>
                            </div>
                            
                        </div>
                    
            </div>

            <div className="mini_gallery_jardin">
                <div className="mini_gallery_wrap">
                    <img src={TableZoom} alt="" />
                    <img src={AlleeBas} alt="" />
                    <img src={Terrasse} alt="" />
                    <img src={Bar} alt="" />
                    <img src={TableNight} alt="" />
                    <img src={VoyageurJour} alt="" />
                </div>
            </div>
            </section>

            
            
            <FooterJardin/>
        </div>
        
    </div>
  )
}

export default LeJardin

