import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/old/Home'
import Home2 from './components/Home2';

import ScrollToTop from './components/hooks/ScrollToTop';
import StickyNavbar from './components/layout/StickyNavbar';
import Gallery from './components/Gallery';

// LE JARDIN
import LeJardin from './components/LeJardin'
// import GalleryJardin from './components/GalleryJardin'
// import About from './components/old/About'
// import ContactJardin from './components/ContactJardin'

// LE CATAMARAN
// import LeCatamaran from './components/LeCatamaran'
// import GalleryCatamaran from './components/GalleryCatamaran'
// import OffersCatamaran from './components/OffersCatamaran';
// import ContactCata from './components/ContactCata'

// LA PLAGE
import LaPlage from './components/LaPlage'
// import ContactPLage from './components/ContactPlage'
// import AboutPlage from './components/AboutPlage';
// import GalleryPlage from './components/GalleryPlage';

// ILE PLATE
import LilePlate from './components/LilePlate';
// import ContactIlePlate from './components/ContactIlePLate';
// import GalleryIlePlate from './components/GalleryIlePlate';

// LA RIVIERE
import LaRiviere from './components/LaRiviere';

import './assets/css/main.css'

function App() {

  return (
    <Router>
      
      
        <div className="App">
        <ScrollToTop/>
        <StickyNavbar  />
              <Routes>
              
                <Route path="/" exact={true} element={<Home2/>} />
                <Route path="/lejardin" exact={true} element={<LeJardin/>} />
                  {/* <Route path="/lejardin/gallery" exact={true} element={<GalleryJardin/>} />
                  <Route path="/lejardin/about" exact={true} element={<About/>} />
                  <Route path="/lejardin/contact" exact={true} element={<ContactJardin/>} /> */}

                {/* <Route path="/lecatamaran" exact={true} element={<LeCatamaran/>} /> 
                  <Route path="/lecatamaran/gallery" exact={true} element={<GalleryCatamaran/>} /> 
                  <Route path="/lecatamaran/offers" exact={true} element={<OffersCatamaran/>} /> 
                  <Route path="/lecatamaran/contact" exact={true} element={<ContactCata/>} />  */}
                
                <Route path="/laplage" exact={true} element={<LaPlage/>} /> 
                  {/* <Route path="/laplage/contact" exact={true} element={<ContactPLage/>} /> 
                  <Route path="/laplage/about" exact={true} element={<AboutPlage/>} /> 
                  <Route path="/laplage/gallery" exact={true} element={<GalleryPlage/>} />  */}
                
                <Route path='/ile_plate' exact={true} element={<LilePlate/>}/>
                {/* <Route path='/ile_plate/contact' exact={true} element={<ContactIlePlate/>}/>
                <Route path='/ile_plate/gallery' exact={true} element={<GalleryIlePlate/>}/> */}

                  <Route path="/lariviere" exact={true} element={<LaRiviere/>}/>
                  <Route path='/gallery' exact={true} element={<Gallery/>}/>
              </Routes>
          
        </div>
      </Router>
  );
}

export default App;
// https://www.freecodecamp.org/news/how-to-set-up-https-locally-with-create-react-app/
// HTTPS