import React, {useState, useEffect} from 'react'
import emailJS from 'emailjs-com';
import axios from "axios";

// LANGUAGE
import { useLanguageStore } from '../subcomponents/LanguageStore';
import {contactLeJardin} from "../translator/le_jardin/contactLeJardin"



function ReservationForm(prop) {
  const [sentEmail, setSentEmail] = useState(false)
  const restaurant = prop.prop
  const [ip, setIP] = useState('');
  const [disabled, setDisabled] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')
  const [email, setEmail] = useState({
    firstname: '',
    lastname: "",
    phone:"",
    email: "",
    restaurant: prop.prop,
    message:""
  })

  const handleChange = (event) => {
    setEmail({ ...email, [event.target.name]: event.target.value });
}
  const language = useLanguageStore((state) => state.language)

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("sending email", email)
    setDisabled(true);
    setTimeout(() => {
        setDisabled(false);
    }, 4000)
    try {
      axios({
         method: "POST",
         url : '/contact',
         data : {'email': email}
      }).then((response) => {
        console.log('reponse', response)
        setSentEmail(true)
        if(response.data === 'mail_sent') {
          console.log('mail sendf')
          if (language ==="français") {
            console.log('flash français')
            setFlashMessage('Merci pour votre message, nous vous répondrons dès que possible.')
          } else {
            setFlashMessage('Thank you for your message, we will reply as soon as possible.')
          }
          setSentEmail(true)
          console.log('messa', sentEmail)
        }
        if (response.data === 'email_exceed') {
          console.log('email exceed')
          if (language ==="français") {
            setFlashMessage("Vous avez déjà envoyé un mail.")
          } else {
            setFlashMessage('You already sent an email.')
          }
          setSentEmail(true)
        }
        setSentEmail(true)
        console.log('flash', flashMessage)
      })
      
      } catch (err) {
        console.log("posting ertror", err)
      }
    // e.preventDefault();
    // //service ID, template ID, e.target, userID/public key
    // emailJS.sendForm('service_cbxkj87', 'template_px0l7q5', e.target, 'I54UazDYnYNZLVmyc')
    // .then((result) => {
    //     console.log("email sent", result.text);
    //     setSentEmail(true)
    // }, (error) => {
    //     console.log(error.text);
    // });
    // e.target.reset();

}

  return (
    <div className='reservation_form_container'>
      {sentEmail ? 
      <div className='reservation_flash_msg'>{flashMessage}</div> 
      : <div className='transparent'>hddtgdgh</div>}
      <form autoComplete="off" className="reservation_form" onSubmit={sendEmail}>
        <div className="contact_form_inputgrp">
            <input onChange={handleChange}  type="text" name="firstname" placeholder={contactLeJardin[language].firstName} required className={restaurant === "jardin" ? "reservation_input lejardin_border_focus1" : restaurant === "plage" ? "reservation_input laplage_border_focus1" : restaurant === "riviere" ? "reservation_input lariviere_border_focus1" : "reservation_input lecata_border_focus1"}/>
            <input onChange={handleChange}  type="text" name="lastname" placeholder={contactLeJardin[language].lastName}  required className={restaurant === "jardin" ? "reservation_input lejardin_border_focus1" : restaurant === "plage" ? "reservation_input laplage_border_focus1" : restaurant === "riviere" ? "reservation_input lariviere_border_focus1" :  "reservation_input lecata_border_focus1"}/>
        </div>

        <div className="contact_form_inputgrp">
            <input onChange={handleChange}  type="text" name="phone" placeholder={contactLeJardin[language].phone}  required className={restaurant === "jardin" ? "reservation_input lejardin_border_focus1" : restaurant === "plage" ? "reservation_input laplage_border_focus1" : restaurant === "riviere" ? "reservation_input lariviere_border_focus1" :  "reservation_input lecata_border_focus1" }/>
            <input onChange={handleChange}  type="text" name="email" placeholder={contactLeJardin[language].email}  required className={restaurant === "jardin" ? "reservation_input lejardin_border_focus1" : restaurant === "plage" ? "reservation_input laplage_border_focus1" : restaurant === "riviere" ? "reservation_input lariviere_border_focus1" : "reservation_input lecata_border_focus1"}/>
        </div>
        <textarea onChange={handleChange}  name="message" type="text" placeholder={contactLeJardin[language].message}  className={restaurant === "jardin" ? "reservation_comment lejardin_border_focus1" : restaurant === "plage" ? "reservation_comment laplage_border_focus1" : restaurant === "riviere" ? "reservation_comment lariviere_border_focus1" : "reservation_comment lecata_border_focus1"} />
        <button  type="submit"  className={restaurant === "jardin" ? "reservation_submit lejardin_bg_color1 lejardin_border1 lejardin_hover1" : restaurant === "plage" ? "reservation_submit laplage_bg_color1 laplage_border1  laplage_hover1" :  restaurant === "riviere" ? "reservation_submit lariviere_bg_color1 lariviere_border1  lariviere_hover1" : "reservation_submit lecata_bg_color1 lecata_border1  lecata_hover1" }>{contactLeJardin[language].send} </button>
      </form>
      
    </div>
  )
}

export default ReservationForm