export const tradJardin = {
    français: {
        restaurant: "restaurant",
        ede: 'Bien sûr, ici, on se délecte de plâts hors du commun, on partage une bonne bouteille de vin et on lève son verre à la beauté de Maurice. Mais surtout, on vie notre passion, on s’amuse et on profite de la vie.',
        eat1: "Tout droit venus du sud de la France, G. O. et W. ont créé un petit paradis provençal au mileu de l'Océan Indien. ",
        eat2: "Cette cuisine française et italienne fait du bien à l'esprit et au corps. ",
        eat3: "Ajoutez à cela une pincée de culture mauricienne et vous obtennez un équilibre complexe :olive ou piment, romarin ou curry mais aussi vin ou rhum.",
        eat4: "Fermez vos yeux, ralentissez et savourez chaque bouchée ! ",
        drink1: "Asseyez-vous et détendez-vous, ils s'occupent de tout. ",
        drink2: "Qu'avez-vous envie de boire ? ",
        drink3: "Un délicieux jus de fruits frais ? Gorgés de vitamines, ils rechargeront vos batteries pour la journée.",
        drink4: "Ou un cocktail ? Attention, ils sont si bons que vous pourriez en commander un autre.",
        drink5: "Commandez peut-être du vin pour accompagner votre repas. Vous êtes plus dégustateur que connaisseur ? Les serveurs se feront un plaisir d'éclairer votre choix.",
        enjoy1: "Saviez-vous que Le Jardin est habité ? ",
        enjoy2: "Si, si je vous assure!",
        enjoy3: "Venez contempler les plantes tropicales, admirer les fleurs  et écouter les beaux oiseaux.  Et, si vous avez de la chance … vous apercevrez peut-être les magnifiques geckos verts.",
        enjoy4: "Profitez du cadre, de la cuisine et de la sympathie de l'équipe du Jardin.",
        enjoy5: "Note personnelle de l'éditrice/cliente : essayez les sushis frais.",
        reservation: 'réservation',
        bookBtn: "réserver une table",
        phone: "+230 5702 5956"
    },
    english: {
        restaurant: "restaurant",
        ede: "Of course, here, we revel in extraordinary plates, we share a good bottle of wine and we toast to the beauty of Mauritius nature. But more importantly, we live our passion, we have fun and we enjoy life.",
        eat1: "Coming straight from the South of France, G. O. and W. created a little provençale paradise in the middle of the Indian Ocean.",
        eat2: "This French and Italian cuisine is good for your mind and body. ",
        eat3: "Add a pinch of Mauritian culture and you get a complex balance:  olive or pepper, rosemary or curry but also ... wine or rhum.",
        eat4: "Close your eyes, slow down and savor each bite !",
        drink1: "Sit back and relax, they will take care of everything. ",
        drink2: "What do you feel like drinking ? ",
        drink3: "A delicious fresh juice ? Full of vitamins, they will recharge your batteries for the day.",
        drink4: "Or a cocktail ? Becareful, they are so good, you might order another one.",
        drink5: "Maybe order some wine to accompany your meal. Your are more of a taster than a connoisseur ? The waiters will gladly suggest wines pairings.",
        enjoy1: "Did you know that Le Jardin is inhabited ? ",
        enjoy2: "Yes it is !",
        enjoy3: "Come and look at the tropical plants, admire the flowers, listen to the beautiful birds. And, if you are lucky … you might even see the magnificent light green geckos.",
        enjoy4: "Enjoy the scenery, the cuisine and the sympathy of Le Jardin team.",
        enjoy5: "Personal editor/customer note : try the fresh sushi.",
        reservation: 'reservation',
        bookBtn: "book a table now",
        phone: "+230 5702 5956"

    },
    pусский: {
        restaurant: "ресторан",
        ede: "Вы насладитесь изысканными блюдами и вином , а также поднимите бокалы за красоту Маврикия. Но особенно",
        eat1: "Прибыв с Лазурного Берега ,G . O . и W. создали для вас маленький французский рай посреди Индийского океана.",
        eat2: "Эта франко-итальянская кухня доставит вам удовольствие.",
        eat3: "Добавте сюда щепотку маврикийской культуры и вы получите идеальный баланс между оливками и прянностями , розмарином и карри а также между вином и ромом", 
        eat4: "Закройте глаза , замедлите ритм и насладитесь моментом.",
        drink1: "Сядьте и расслабтесь , мы к вашим услугам .",
        drink2: "Что вы желаете пить ?",
        drink3: "Свежий фруктовый сок ? Полон витамин ,он вам даст заряд на день .",
        drink4: "Либо коктейль ? Настолько вкусный ,что захочется повторить заказ.",
        drink5: "Если вы желаете подобрать вино к блюдам наши официанты-сомелье к вашим услугам.",
        enjoy1: 'Знаете ли вы ,что "Jardin" обитаем ?', 	
        enjoy2: "да,я вас уверяю ! ",
        enjoy3: "Полюбуйтесь тропическими растениями , удивительными цветами и послушайте пение птиц . Если вам улыбнётся удача ... может вы увидите",
        enjoy4: 'Насладитесь атмосферой , кухней и симпатией сервиса "Jardin"',
        enjoy5: "информация от редактора клиенту: попробуйте обязательно суши .",
        reservation: "резервация",
        bookBtn: "зарезервировать стол",
        phone: "+230 5702 5956", 
    }
}