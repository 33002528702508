import React, {useState, useRef, useEffect} from 'react'

// LANGUAGE
import { useLanguageStore } from '../subcomponents/LanguageStore';
import {vocab} from '../translator/vocab'
import {restaurantID} from '../translator/id'
import LogoPlageNobg from '../../assets/pictures/logos/logo_plage_sans_bg.png'
import LogoCataNobg from '../../assets/pictures/logos/logo_cata_sans_bg.png'
import LogoJardinNobg from '../../assets/pictures/logos/logo_jardin_sans_bg.png'
import LogoIlePlateNobg from '../../assets/pictures/logos/logo_ile_plate_nobg.png'

import { FaMapMarkerAlt } from "react-icons/fa";

function Home() {
 
    const homeContainer = useRef()
    const mouseOverLeft = () => {homeContainer.current.classList.add('hover-left')}
    const mouseOutLeft = () => {homeContainer.current.classList.remove("hover-left")}
    const mouseOverCenter = () => {homeContainer.current.classList.add('hover-center')}
    const mouseOutCenter = () => {homeContainer.current.classList.remove("hover-center")}
    const mouseOverRight = () => {homeContainer.current.classList.add('hover-right')}
    const mouseOutRight = () => {homeContainer.current.classList.remove("hover-right")}

    const language = useLanguageStore((state) => state.language)
    const setToFrench = useLanguageStore((state) => state.setToFrench)
    const setToEnglish = useLanguageStore((state) => state.setToEnglish)
    const setToRussian = useLanguageStore((state) => state.setToRussian)
    const handleCLickFrench = () => {
        setToFrench()
      }
      const handleCLickEnglish = () => {
        setToEnglish()
      }
      const handleCLickRussian = () => {
        setToRussian()
      }
  return (
    <div  ref={homeContainer} className='page_container'>
        <div className="language_container_home">
        <h3 className="nav_language_title">{language}</h3>
        <div className="langague_flags_container">
          <div className="flag_container french" onClick={handleCLickFrench}></div>
          <div className="flag_container english" onClick={handleCLickEnglish}></div>
          <div className="flag_container russian" onClick={handleCLickRussian}></div>
        </div>
        
      </div>
        {/* <h1 className='home_page_title'>CHOISISSEZ VOTRE RESTAURANT</h1> */}
        <div className="split left" onMouseOver={mouseOverLeft} onMouseOut={mouseOutLeft}>
            {/* <h1>Le Poivrier</h1>
            <h2>Le Catamaran</h2> */}
            <img src={LogoIlePlateNobg} alt=""/>
            <p><FaMapMarkerAlt/>  {restaurantID[language].ilePlateAdressCut}</p>
            <a href="/ile_plate" className="btn_home">{vocab[language].seeMore}</a>
        </div>
        <div   className="split center" onMouseOver={mouseOverCenter} onMouseOut={mouseOutCenter}>
            {/* <h1 >Le Poivrier</h1>
            <h2>Le Jardin</h2> */}
            <img src={LogoJardinNobg} alt=""/>
            <p><FaMapMarkerAlt/> {restaurantID[language].jardinAdressCut}</p>
            <a href="/lejardin" className="btn_home">{vocab[language].seeMore}</a>
        </div>  
        <div className="split right" onMouseOver={mouseOverRight} onMouseOut={mouseOutRight}>
            {/* <h1>Le Poivrier</h1>
            <h2>La Plage</h2> */}
            <img src={LogoPlageNobg} alt=""/>
            <p><FaMapMarkerAlt/> {restaurantID[language].plageAdressCut}</p>
            <a href="/laplage" className="btn_home">{vocab[language].seeMore}</a>
        </div>
        
    </div>
  )
}

export default Home