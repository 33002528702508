export const TradNavbar = {
        français: {
                home: "Accueil",
                gallery: "Galerie",
                contact: 'Contact',
                about: "à propos",
                offers: "offres",
                nosRestaurants: "Nos Restaurants",
                menu: "Menu",
                jardin: "Le Jardin",
                plage: "La Plage",
                riviere: "La Rivière",
                ilePlate: "L'île Plate",
                quickLinks: "liens Rapides :",
                
        },
        english: {
                home: "Home", 
                gallery: "Gallery",
                contact: 'Contact',
                about: "about us",
                offers: "offers",
                nosRestaurants: "Our Restaurants",
                menu: "Menu",
                jardin: "The Garden",
                plage: "The Beach",
                riviere: "The River",
                ilePlate: "The Flat Island",
                quickLinks: "Quick Links: ",
                
        },
        pусский: {
                home: "Главная",
                gallery: "Галерея",
                contact: "kонтакты",
                about: "о нас",
                offers: "предложения",  
                nosRestaurants: "Наши Рестораны",
                menu: "Mеню",
                jardin: "Сад",
                plage: "Пляж",
                riviere: "Река",
                ilePlate: "Плоский остров",
                quickLinks: 'Быстрые ссылки :',
                
        }
        

}
// export const EnglishNav = {
//         gallery: "gallery",
//         contact: 'contact',
//         about: "about us",
//         offers: "offers"
// }

// export const RussianNav = {
//         gallery: "галерея",
//         contact: "контакты",
//         about: "о нас",
//         offers: "предложения",
// }