export const riviereTrad = {
    français: {
        titre1: "Cuisine Epatante",
        titre2: "La Rivière Tamarin",
        titre3: "Belle Nature",
        paragraphe1: 'Laissez-vous transporter dans un havre de détente absolue. Notre restaurant au bord de la rivière Tamarin offre un cadre idyllique où vous pourrez vous ressourcer et vous relaxer.',
        paragraphe2: "Notre menu met à l'honneur une cuisine française et italienne exquise. Des saveurs authentiques se marient harmonieusement pour créer des plats qui éveilleront vos papilles.",
        paragraphe3: "Plongez dans une expérience culinaire envoûtante au bord de la rivière Tamarin. Laissez-vous bercer par le doux murmure de l'eau tandis que vous dégustez des plats français et italiens raffinés, préparés avec des ingrédients frais et de saison.",
        paragraphe4: "Découvrez notre spacieux restaurant niché au cœur d'une nature luxuriante. Notre établissement vous accueille sous les arbres majestueux, offrant une ambiance unique où vous pouvez vous détendre, profiter d'une atmosphère conviviale et savourer des mets délicieux.",

    },
    english: {
        titre1: "impressive Food",
        titre2: "The Tamarin river",
        titre3: "Beautiful Nature",
        paragraphe1: "Allow yourself to be transported to a haven of absolute relaxation. Our restaurant by the Tamarin River offers an idyllic setting where you can rejuvenate and unwind.",
        paragraphe2: "Our menu showcases exquisite French and Italian cuisine. Authentic flavors blend harmoniously to create dishes that will tantalize your taste buds.",
        paragraphe3: "Immerse yourself in an enchanting culinary experience by the banks of the Tamarin River. Let yourself be lulled by the gentle murmuring of the water as you indulge in refined French and Italian dishes, prepared with fresh, seasonal ingredients.",
        paragraphe4: "Discover our spacious restaurant nestled in the heart of lush nature. Our establishment welcomes you under majestic trees, offering a unique ambiance where you can relax, enjoy a friendly atmosphere, and savor delicious dishes.",

    }, 
    pусский: {
        titre1: "Впечатляющая кухня",
        titre2: "Река Тамарин",
        titre3: "Прекрасная природа",
        paragraphe1: "Позвольте себе погрузиться в оазис абсолютного релакса. Наш ресторан на берегу реки Тамарин предлагает идиллическую обстановку, где вы сможете восстановить силы и расслабиться.",
        paragraphe2: "Наше меню представляет изысканную французскую и итальянскую кухню. Аутентичные вкусы гармонично сочетаются, создавая блюда, которые разбудят ваши вкусовые рецепторы.",
        paragraphe3: "Окунитесь в захватывающий кулинарный опыт на берегу реки Тамарин. Позвольте себе забыться под мягким шепотом воды, наслаждаясь изысканными французскими и итальянскими блюдами, приготовленными из свежих и сезонных ингредиентов.",
        paragraphe4: "Откройте для себя наш просторный ресторан, утопающий в пышной природе. Наше заведение расположено под величественными деревьями и создает уникальную атмосферу, где вы можете расслабиться, насладиться дружелюбной обстановкой и наслаждаться изысканными блюдами.",

    
    }

}