import React, {useState, useRef, useEffect} from 'react'
import { AiOutlineLeft } from "react-icons/ai"
import { AiOutlineRight } from "react-icons/ai"
import { AiOutlineClose } from "react-icons/ai"

const WSPGallery = ({galleryImages, compressedGallery}) => {
    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModel] = useState(false)
    const fullImageRef = useRef()
    const arrowLeft = useRef()
    const arrowRight = useRef()

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModel(true)
    }

    const handleCloseModal = () => {
        setOpenModel(false)
        console.log('close')
    }
    const prevSlide = () => {
        slideNumber === 0 ? setSlideNumber(galleryImages.length - 1) : setSlideNumber(slideNumber - 1)
        console.log('prev slide')
    }
    const nextSlide = () => {
        console.log("nextslide")
        slideNumber + 1 === galleryImages.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)
        console.log('next slide')
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
        if (fullImageRef.current && !fullImageRef.current.contains(event.target)) {
                if (event.target.className.animVal === 'next_btn' || event.target.className.animVal === 'prev_btn') {
                    
                }
                else {
                setOpenModel(false)
                }
                
         }
             
        };        
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, []);

    
      
 

    return (
        <div className='gallery_container'>
            {openModal && 
                <div className='sliderWrap'>
                    <AiOutlineClose onClick={() => handleCloseModal()} size={30} className='close_btn'/>
                    <AiOutlineLeft ref={arrowLeft} onClick={() =>prevSlide()} size={30}  className='prev_btn' />
                    <AiOutlineRight ref={arrowRight} onClick={() => nextSlide()} size={30} className='next_btn' />
                    <div className="full_screen_img">
                        <img ref={fullImageRef} className="img" src={galleryImages[slideNumber].img} alt="" />
                    </div>
                </div>
            }
       
            <div className="gallery_wrap">
                {
                    compressedGallery && compressedGallery.map((slide, index) => {
                        return(
                            <div onClick={() => handleOpenModal(index)} className="single" key={index}>
                                <img src={slide.img} alt="" />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
// https://www.youtube.com/watch?v=J1dg4WXr3cg
export default WSPGallery