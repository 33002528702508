export const PlageTrad= {
    français: {
        titre1: "Bord de Mer",
        titre2: "Ambiance Tropicale",
        titre3: "Belle Nature",
        paragraphe1: "Bienvenue au Poivrier La Plage, un restaurant en bord de plage qui incarne l'essence de la cuisine française et italienne. Niché dans un cadre idyllique, ce lieu vous offre une expérience culinaire inoubliable avec une vue imprenable sur un îlot.",
        paragraphe2: "Plongez dans une expérience gastronomique les pieds dans le sable. Profitez d'une vue spectaculaire sur l'horizon marin, où les eaux turquoise rencontrent le ciel sans fin. Laissez-vous bercer par le bruit apaisant des vagues pendant que vous dégustez vos plats.",
        paragraphe3: "Imprégné de l'esprit d'une paillote en bord de mer, les éléments rappelant le sable, les coquillages et les palmiers créent une atmosphère tropicale et décontractée. Notre deck en hauteur vous permet de contempler la vue panoramique tout en savourant votre repas, ajoutant une touche de grandeur à votre expérience.",
        paragraphe4: "Après avoir savouré votre repas, continuez à profiter de la magnifique plage qui s'étend à vos pieds. Une ballade paisible le long du rivage, les pieds dans le sable, vous permettra de vous reconnecter avec la nature et de prolonger votre moment de détente."
    },
    english: {
        titre1: "Seaside",
        titre2: "Tropical Ambiance",
        titre3: "Beautiful Nature",
        paragraphe1: "Welcome to Le Poivrier The Beach, a beachfront restaurant that embodies the essence of French and Italian cuisine. Nestled in an idyllic setting, this place offers you an unforgettable culinary experience with stunning views of an island.",
        paragraphe2: "Immerse yourself in a gastronomic experience with your feet in the sand. Enjoy a spectacular view of the ocean horizon, where turquoise waters meet the endless sky. Let yourself be lulled by the soothing sound of the waves as you savor your dishes.",
        paragraphe3: "Immerse yourself in the spirit of a seaside beach hut, with elements reminiscent of sand, seashells, and palm trees creating a tropical and relaxed atmosphere. Our elevated deck allows you to enjoy a panoramic view while savoring your meal, adding a touch of grandeur to your experience.",
        paragraphe4: "After enjoying your meal, continue to indulge in the beautiful beach stretching out before you. Take a peaceful stroll along the shoreline, with your feet in the sand, to reconnect with nature and prolong your moment of relaxation."

    },
    pусский: {
        titre1: "Mорская Cторона",
        titre2: "Тропическая атмосфера",
        titre3: "Прекрасная природа",
        paragraphe1: 'Добро пожаловать в ресторан "Le Poivrier La Plage" (Ле Пуазье Ла Плаж), ресторан на берегу пляжа, воплощающий суть французской и итальянской кухни. Уютно расположенный в идиллической обстановке, это место предлагает незабываемый кулинарный опыт с прекрасным видом на остров.',
        paragraphe2: "Окунитесь в гастрономическое путешествие с песком под ногами. Насладитесь впечатляющим видом на морской горизонт, где бирюзовые воды встречаются с бесконечным небом. Позвольте себе быть унесенным успокаивающим шумом волн, наслаждаясь блюдами.",
        paragraphe3: "Окутайтесь атмосферой пляжного шале, где элементы, напоминающие песок, ракушки и пальмы, создают тропическую и расслабленную обстановку. Наша высокая площадка позволит вам насладиться панорамным видом, наслаждаясь своим обедом и добавляя изысканности вашему опыту.",
        paragraphe4: "После того, как вы насладились вашим обедом, продолжайте наслаждаться прекрасным пляжем, простирающимся у ваших ног. Спокойная прогулка вдоль берега, с песком под ногами, позволит вам вновь связаться с природой и продлить ваше мгновение релаксации."

    }
}