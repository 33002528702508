export const contactLeJardin = {
    français: {
        contactUs: 'nous contacter',
        question: 'Vous souhaitez nous contacter ?',
        fillOut: "Remplissez le formulaire ci-dessous",
        noReservation: 'Pas de réservation.',
        firstName: "Prénom",
        lastName:"Nom",
        phone:"Téléphone",
        email:"Email",
        message: "Message",
        thankYou: "Merci pour votre message, nous vous répondrons dès que possible.",
        send: "envoyer",
        practicalInfo: "informations pratiques",
        cuisine: "cuisine",
        cuisineDescription: "Cuisine française et italienne",
        cuisineDescription2: 'poissons locaux et sushis',
        meansPayment: "moyens de paiement",
        payments: "Carte de crédit, espèce, Juice",
        openings: "horaires",
        tuesday: "Mar",
        wednesday: 'mer',
        saturday: 'sam',
        sunday: "dim",
        monday: "lundi",
        friday: 'vend',
        closed: 'fermé',

    },
    english: {
        contactUs: 'contact us',
        question: 'Want to contact us ?',
        fillOut: "Fill out the form below.",
        noReservation: 'No reservation.',
        firstName: "Firstname",
        lastName:"Lastname",
        phone:"Phone number",
        email:"Email",
        message: "Message",
        thankYou: "Thank you for your message, we will reply as soon as possible.",
        send: "send",
        practicalInfo: "practical information",
        cuisine: "cuisine",
        cuisineDescription: "French, Italian cuisine",
        cuisineDescription2: "local fish, sushis",
        meansPayment: "means of payment",
        payments: "Credit card, cash, Juice",
        openings: "openings",
        tuesday: "tue",
        wednesday: 'wed',
        saturday: 'sat',
        sunday: "sun",
        monday: "mon",
        friday: "fri",
        closed: 'closed',
    },

    pусский: {
        contactUs: "Наши контакты",
        question: "Желаете к нам обратиться ?",
        fillOut: "Заполните анкету ниже",
        noReservation: "Без резервации",
        firstName: "Имя",
        lastName: "Фамилия",
        phone: "телефон",
        email: "эмайл",
        message: "сообщение",
        thankYou: "Спасибо за ваше обращение ,мы вам ответим в ближайшее время.",
        send: "послать",
        practicalInfo: "Полезная информация",
        cuisine: "кухня",
        cuisineDescription: "Французская и итальянская кухня", 
        cuisineDescription2: "регионная рыба и суши ",
        meansPayment: "способы оплаты",
        payments: "кредитные карты ,наличные ,Juice",
        openings: "время открытия",
        
        monday: "Пн",
        tuesday: "Вт",
        wednesday: "Ср",
        thursday: "Чт",
        friday: "Пт",
        saturday: "Сб",
        sunday: "Вс",
        closed: "выходной"
    }
}