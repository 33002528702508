import create from 'zustand'

const languageStore = (set) => ({
    language: "français",
    setToFrench: () => set((state) => ({language: 'français'})),
    setToEnglish: () => set((state) => ({language: 'english'})),
    setToRussian: () => set((state) => ({language: 'pусский'}))
})

export const useLanguageStore = create(languageStore)
