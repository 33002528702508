import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import {HashLink} from "react-router-hash-link"
import { Link, NavLink } from 'react-router-dom'

import {FaBars, FaTimes} from 'react-icons/fa';
import { AiOutlineClose } from "react-icons/ai"
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

import { useLanguageStore } from '../subcomponents/LanguageStore';
import {TradNavbar} from '../translator/tradNavbar'

import LogoSansBg from '../../assets/pictures/logos/logo_sans_bg.png'

function StickyNavbar2() {
  const navRef = useRef();
  const dropdown = useRef()
  const menu = useRef()



  const [stickyNav, setStickyNav] = useState(false)
  const [subMenu, setSubMenu] = useState(false)
  const [checkMenu, setCheckMenu] = useState(false)

  const language = useLanguageStore((state) => state.language)

  const setToFrench = useLanguageStore((state) => state.setToFrench)
  const setToEnglish = useLanguageStore((state) => state.setToEnglish)
  const setToRussian = useLanguageStore((state) => state.setToRussian)
  
  // LANGUAGE
  const handleCLickFrench = () => {
    setToFrench()
  }
  const handleCLickEnglish = () => {
    setToEnglish()
  }
  const handleCLickRussian = () => {
    setToRussian()
  }

  // STICKY NAV
  const handleScroll = () => {
      const position = window.scrollY;
      if (position > 0) {
        console.log('more')
        setStickyNav(true)
      } else {
        setStickyNav(false)
        console.log('less')
      }
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

//   DROPDOWN
  const toggleSubmenu = () => {
    if (subMenu) {
        setSubMenu(false)
    } else {
        setSubMenu(true)
    }
  }
  // const handleDropdownClick = () => {
  //   setSubMenu(false)
  // }

  // RESPONSIVE NAVBAR
  const handleCheckMenu = (e) => {
    e.preventDefault();
          e.stopPropagation();
    if (!checkMenu) {
      setCheckMenu(true)
    }else {
      setCheckMenu(false)
    }
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setCheckMenu(false)
          setSubMenu(false)
      }  
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <header className={stickyNav ? "navbar_header sticky" : "navbar_header"}>
        
        <div className="language_container">
          <h3 className="nav_language_title">{language}</h3>
          <div className="langague_flags_container">
            <div className="flag_container french" onClick={handleCLickFrench}></div>
            <div className="flag_container english" onClick={handleCLickEnglish}></div>
            <div className="flag_container russian" onClick={handleCLickRussian}></div>
          </div>
      </div>
       <NavLink to="/"><img className='nav-logo' src={LogoSansBg}/></NavLink>
       <input ref={menu} type="checkbox" id="menu-bar" checked={checkMenu}/>
        <label ref={navRef} htmlFor="menu-bar" className='menu-bar' onClick={(e) => handleCheckMenu(e)}>{TradNavbar[language].menu}</label>
        <nav className="nav">
            <ul ref={navRef} className="nav_container">
                <li ref={navRef} onClick={toggleSubmenu}>
                    <div   className="nav-link">{TradNavbar[language].nosRestaurants} {subMenu ? <IoMdArrowDropup className='dropdown_icon' size={20}/> : <IoMdArrowDropdown className='dropdown_icon' size={20}/>}</div> 
                    {
                        stickyNav ? 
                        <ul ref={dropdown} className={subMenu ? "navbar_submenu_on_sticky" : "navbar_submenu_off"}>
                            <li><NavLink ref={navRef} to="/lejardin" className="navbar_sublink">{TradNavbar[language].jardin}</NavLink></li>
                            <li ><NavLink ref={navRef} to="/ile_plate" className="navbar_sublink">{TradNavbar[language].ilePlate}</NavLink></li>
                            <li ><NavLink ref={navRef} to="/lariviere" className="navbar_sublink">{TradNavbar[language].riviere}</NavLink></li>
                            <li ><NavLink ref={navRef} to="/laplage" className="navbar_sublink">{TradNavbar[language].plage}</NavLink></li>
                        </ul> 
                    :
                        <ul ref={dropdown} className={subMenu ? "navbar_submenu_on" : "navbar_submenu_off"}>
                            <li ><NavLink ref={navRef} to="/lejardin" className="navbar_sublink">{TradNavbar[language].jardin}</NavLink></li>
                            <li ><NavLink ref={navRef} to="/ile_plate" className="navbar_sublink">{TradNavbar[language].ilePlate}</NavLink></li>
                            <li ><NavLink ref={navRef} to="/lariviere" className="navbar_sublink">{TradNavbar[language].riviere}</NavLink></li>
                            <li ><NavLink ref={navRef} to="/laplage" className="navbar_sublink">{TradNavbar[language].plage}</NavLink></li>
                        </ul> 
                    }
                                   
                </li>
                <li> <HashLink  className="nav-link" to="/#contact" smooth>{TradNavbar[language].contact}</HashLink> </li>
                <li> <NavLink className="nav-link" to="/gallery">{TradNavbar[language].gallery}</NavLink></li>
            </ul>
     
    </nav>
    </header>
      
    

    
  )
}

export default StickyNavbar2
// https://www.youtube.com/watch?v=ZJZVCg2lXSc
// https://github.com/herdoycode/Responsive-React-Navbar/blob/main/ReactNavbar/src/navbar.css
