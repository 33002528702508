import React, {useState, useRef, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"

import NavbarIlePLate from './layout/old/NavbarIlePlate'
import FooterIlePlate from './layout/FooterIlePlate'
import ContactForm from './subcomponents/ContactForm'

// LANGUAGE
import { useLanguageStore } from './subcomponents/LanguageStore';
import {tradIlePlate} from './translator/ile_plate/trad_ile_plate'
import {vocab} from './translator/vocab';
import {tradJardin} from './translator/le_jardin/tradLeJardin'
import {restaurantID} from './translator/id'
import {TradLeCata} from './translator/le_catamaran/tradLeCata'
import {contactLeJardin} from "./translator/le_jardin/contactLeJardin"


// ICONS
import { HiLockClosed } from "react-icons/hi";

// LOGOS 
import LogoPlageNobg from '../assets/pictures/logos/logo_plage_sans_bg.png'
import LogoCataNobg from '../assets/pictures/logos/logo_cata_sans_bg.png'
import LogoJardinNobg from '../assets/pictures/logos/logo_jardin_sans_bg.png'
import LogoIlePlate from "../assets/pictures/logos/logo_ile_plate_nobg.png"
import LogoRiviere from "../assets/pictures/logos/logo_riviere_no_bg.png"

// CUT IMAGES
import CocktailBleu from "../assets/pictures/cuts/cocktail_bleu_cut.png"

// PHOTOS
///paysage
import PlageHaut from '../assets/pictures/lile_plate/plage_haut.jpg'
import Governors from '../assets/pictures/lile_plate/resto_haut.jpg'
import ModelsBeach from "../assets/pictures/lile_plate/models_beach.jpg"
import Band from "../assets/pictures/lile_plate/band.jpg"
import Langouste from "../assets/pictures/lile_plate/langouste.jpg"
import TableInterieur from "../assets/pictures/lile_plate/table_interieur.jpg"
import WalkingBeach from "../assets/pictures/lile_plate/walking_beach.jpg"
import VuePaysage from "../assets/pictures/lile_plate/vue_paysage.jpg"
import Tables from "../assets/pictures/lile_plate/tables.jpeg"

///portrait
import Turtles from '../assets/pictures/lile_plate/turtles.jpg'
import Corals from '../assets/pictures/lile_plate/corals.jpg'
import BabyBird from "../assets/pictures/lile_plate/baby_bird.jpg"




function LilePlate() {

    const language = useLanguageStore((state) => state.language)
    
    document.title="Le POIVRIER - Flat Island";
    document.head.querySelector("[id~=page-description][content]").content="Exceptional Day with Le Poivrier - Flat island. Embark on a journey to a paradise island with the high standard of Le Poivrier. Sunset Boulevard, Grand Bay.";
    return (
      <div className="page2_container">
        {/* <NavbarIlePLate /> */}
        <div className='scroll_container'>
            <div className="fixed_bg_ile_plate"> </div>
           
            <div className="home_logo_container">
                    <img className="home_logo" src={LogoIlePlate}/>
                </div>
            <div className="jardin_grid_container">
                <div className="grid_jardin_left_side">
                    <div className="title ile_plate_title">{tradIlePlate[language].titre1}</div>
                    <div className="image_batch_container">
                        <p>{tradIlePlate[language].description1}</p>
                        <img src={PlageHaut} alt="" className='landscape_image'/>
                    </div>
                    <div className="image_batch_container">
                        <div className="batch_title ile_plate_batch_title">{tradIlePlate[language].sous_titre1}</div>
                        <p>{tradIlePlate[language].description2}</p>
                        <img src={Turtles} alt="" className='portrait_image'/>
                        <img src={Corals} alt="" className='portrait_image move1'/>
                        <img src={BabyBird} alt="" className='portrait_image move2'/>
                    </div>
                </div>
                <div className="grid_jardin_right_side">
                    <div className="image_batch_container">
                        <img src={ModelsBeach} className='landscape_image' alt="" />
                        <div className="batch_title ile_plate_batch_title">{tradIlePlate[language].sous_titre2}</div>
                        <p>{tradIlePlate[language].description3}</p>
                    </div>
                    <div className="image_batch_container">
                        <img src={Governors} alt="" className='landscape_image' />
                        <div className="batch_title ile_plate_batch_title">{tradIlePlate[language].sous_titre3}</div>
                        <p>{tradIlePlate[language].description4}</p>
                    </div>
                    
                    <div className="image_batch_container">
                        
                    </div>
                </div>
            </div>
            <div className="content3">
                <h2 className='contact_h2'>{tradJardin[language].reservation}</h2>
                <div className='reservation_btn_ile_plate'>{tradJardin[language].bookBtn}</div>
                <h1>{restaurantID[language].ilePlatePhone}</h1>
            </div>
            {/* CONTACT */}
            <section className="contact_page_container" id="contact_ileplate">
            <div className="contact_post2_cata">
                <div className="text_container">
                    <h2>{TradLeCata[language].reserver}</h2>
                    <hr className='divider_lecata'/>
                    <p>{tradIlePlate[language].para1} <strong>{tradIlePlate[language].strong1} </strong>{TradLeCata[language].para1suite}</p>
                    <p>{tradIlePlate[language].para2}</p>
                    <p> {tradIlePlate[language].para3} <strong>{tradIlePlate[language].strong3} </strong>{tradIlePlate[language].para2suite}</p>
                    <p>{tradIlePlate[language].para4}<strong>{tradIlePlate[language].strong4}</strong>{tradIlePlate[language].para4Suite}</p>
                    <p><span className='span_resa_cata'>{tradIlePlate[language].menuTitre}</span></p>
                    <p>{tradIlePlate[language].menu}</p>
                    <p>{tradIlePlate[language].para5}<strong>{tradIlePlate[language].strong5}</strong></p>
                    <br></br>
                    <h2>{tradIlePlate[language].privatiser}</h2>
                    <hr className='divider_lecata'/>
                    <p>{tradIlePlate[language].priva1}<strong>{tradIlePlate[language].priva1strong1}</strong>{tradIlePlate[language].priva1Suite}</p>
                    <p>{tradIlePlate[language].priva2}<strong>{tradIlePlate[language].priva2strong1}</strong></p>
                    <p>{tradIlePlate[language].priva3}</p>
                    <p>{tradIlePlate[language].priva4}</p>
                </div>
            </div>
            <div className="contact_post1">
                <div className="left_container">
                    <div className="text_container">
                        <h2>Le Poivrier</h2>
                        <h3 className='lecata_ocean'>{restaurantID[language].titreIlePlate}</h3>
                        <p className='contact_adress'>{restaurantID[language].ilePlateAdress}</p>
                        <p className="contact_phone_lecata">{restaurantID[language].ilePlatePhone}</p>
                    </div>
                </div>
                <div className="right_container">
                
                <iframe className="lecata_gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1194.714526289098!2d57.583094329242826!3d-20.013143139038775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217dab463808f9f3%3A0x1bd0b8ff5cb4b081!2sLe%20Poivrier%20-%20Le%20Catamaran!5e1!3m2!1sen!2smu!4v1667529618547!5m2!1sen!2smu" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            
            <div className="contact_post2">
                <div className="text_container">
                    <h2>{contactLeJardin[language].contactUs}</h2>
                    <hr className='divider_lecata'/>
                    <p>{contactLeJardin[language].question}</p>
                    <p>{contactLeJardin[language].fillOut}</p>
                    <p><span>{contactLeJardin[language].noReservation}</span></p>
                </div>
                <div className="contact_form_container">
                    <ContactForm prop="ile_plate" />
                </div>
            </div>
            <div className="contact_else">
                <h2>{vocab[language].else}</h2>
                <hr className='divider_lecata'/>
                <div className="contact_else_container">
                    <HashLink to="/laplage#contact_laplage" className="contact_plage" smooth>
                        <img src={LogoPlageNobg} alt=""/>
                    </HashLink>
                    <HashLink to="/lejardin#contact_lejardin" className="contact_jardin" smooth>
                        <img src={LogoJardinNobg} alt=""/>
                    </HashLink>
                    <HashLink to="/lariviere#contact_lariviere" className="contact_riviere" smooth>
                        <img src={LogoRiviere} alt=""/>
                    </HashLink>
                    
                </div>
            </div>
            <div className="contact_post3">
                <div className="contact_ile_plate_bg"></div>
                <img src={CocktailBleu} className="image_cut_contact_ile_plate" alt="" />
                    <div className="contact_post3_left">
                        <div className="text_container">
                            <h2>{contactLeJardin[language].practicalInfo}</h2>
                            <hr className='divider_lecata'/>
                            <div className="practical_infos">
                                <p>{tradIlePlate[language].practicalInfo}</p>
                                <h3>{contactLeJardin[language].cuisine}</h3>
                            <p>{tradIlePlate[language].cuisineDescription} <br></br> {tradIlePlate[language].cuisineDescription2}</p>
                            <h3>{contactLeJardin[language].meansPayment}</h3>
                            <p>{contactLeJardin[language].payments}</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact_openings_cata">
                        <h2>{contactLeJardin[language].openings}</h2>
                        <hr className='divider_lecata'/>
                        <div className="day_wrap">
                            <h5 className="day">
                                <span>{contactLeJardin[language].saturday}</span>
                               
                            </h5>
                            <p className="hours"> 
                                <span className="hour">~ 8h30 - 16h</span>
                            </p>
                        </div>
                        <div className="day_wrap">
                            <h5 className="day">
                                <span>{contactLeJardin[language].sunday}</span>
                                <span> - </span>
                                <span>{contactLeJardin[language].friday}</span>
                            </h5>
                            <p className="hours">
                                <HiLockClosed className="icon_fermé_cata" size={20}/>
                                {contactLeJardin[language].closed}
                            </p>
                        </div>
                    </div>
            </div>
            <div className="mini_gallery_jardin">
                <div className="mini_gallery_wrap">
                    <img src={Band} alt="" />
                   
                    <img src={Langouste} alt="" />
                    <img src={WalkingBeach} alt="" />
                    <img src={Tables} alt="" />
                    <img src={VuePaysage} alt="" />
                    <img src={TableInterieur} alt="" />
                </div>
            </div>
            </section>
  
            <FooterIlePlate/>
        </div>
        
       
              
        
      </div>
    )
  }
  
  export default LilePlate;