import React from 'react'

import { useLanguageStore } from '../subcomponents/LanguageStore';
import { tradFooter, week } from '../translator/le_jardin/tradfooter';
import {restaurantID} from '../translator/id'

import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { AiOutlineMail, AiOutlineInstagram } from "react-icons/ai";
import { FaTripadvisor } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";



function Footer() {
    // LANGUAGE
    const language = useLanguageStore((state) => state.language)
    const setToFrench = useLanguageStore((state) => state.setToFrench)
    const setToEnglish = useLanguageStore((state) => state.setToEnglish)
    const setToRussian = useLanguageStore((state) => state.setToRussian)

    const handleCLickFrench = () => {
      setToFrench()
    }
    const handleCLickEnglish = () => {
      setToEnglish()
    }
    const handleCLickRussian = () => {
      setToRussian()
    }

  return (
    <div className='footer'>
      <div className="footer_container">
        <div className="footer_contact_infos">
          <h3 className='footer_title'>{tradFooter[language].contact}</h3>
          <div className="footer_contact_info">
          <FaMapMarkerAlt className='contact_icon' size={30}/>
            <p>{restaurantID[language].cataAdress}</p>
          </div>
          <div className="footer_contact_info">
            <IoIosPhonePortrait className='contact_icon'size={30}/>
            <p>{restaurantID[language].cataPhone}</p>
          </div>
          <div className="footer_contact_info">
            <div className="footer_language_container">
              <h3 className="footer_language_title">{language}</h3>
              <div className="langague_flags_container">
                <div className="flag_container french" onClick={handleCLickFrench}></div>
                <div className="flag_container english" onClick={handleCLickEnglish}></div>
                <div className="flag_container russian" onClick={handleCLickRussian}></div>
              </div>
          </div>
          </div>
          <div className="social_media">
            <a className="social_media_link_lecata" href="https://www.tripadvisor.com/Restaurant_Review-g488103-d12242815-Reviews-Le_Poivrier-Grand_Baie.html" target="_blank" rel="noopener noreferrer"><FaTripadvisor  size={30} className='social_media_icon'/></a>
            <a className="social_media_link_lecata" href="https://www.instagram.com/lepoivrier_ile_plate/" target="_blank" rel="noopener noreferrer"><AiOutlineInstagram size={30} className='social_media_icon'/></a>
            <a className="social_media_link_lecata" href="https://www.facebook.com/lePoivrierCafeRestau/" target="_blank" rel="noopener noreferrer"><BsFacebook  size={30} className='social_media_icon'/></a>
          </div>
        </div>
      </div>
      <div className="footer_container">
          <h3 className='footer_title'>{tradFooter[language].openings}</h3>
          <div className="footer_openings">
            
          
          
            <li className='footer_openings_li'>{week[language].saturday} <span className='openings'>~ 8h30 - 16h00</span></li>
            <li className='footer_openings_li'>{week[language].sunday} <span className='openings'>{language ==="français" ? tradFooter.français.closed : language === "english" ? tradFooter.english.closed : tradFooter.pусский.closed}</span></li>
            <li className='footer_openings_li'>{week[language].monday} <span className='openings'>{language ==="français" ? tradFooter.français.closed : language === "english" ? tradFooter.english.closed : tradFooter.pусский.closed}</span></li>
            <li className='footer_openings_li'>{week[language].tuesday} <span className='openings'>{language ==="français" ? tradFooter.français.closed : language === "english" ? tradFooter.english.closed : tradFooter.pусский.closed}</span></li>
            <li className='footer_openings_li'>{week[language].wednesday} <span className='openings'>{language ==="français" ? tradFooter.français.closed : language === "english" ? tradFooter.english.closed : tradFooter.pусский.closed}</span></li>
            <li className='footer_openings_li'>{week[language].thursday} <span className='openings'>{language ==="français" ? tradFooter.français.closed : language === "english" ? tradFooter.english.closed : tradFooter.pусский.closed}</span></li>
            <li className='footer_openings_li'>{week[language].friday} <span className='openings'>{language ==="français" ? tradFooter.français.closed : language === "english" ? tradFooter.english.closed : tradFooter.pусский.closed}</span></li>
          </div>
        </div>
      <div className="footer_container">
        <h3 className='footer_title'>{tradFooter[language].location}</h3>
        <iframe className="footer_gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1194.714526289098!2d57.583094329242826!3d-20.013143139038775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217dab463808f9f3%3A0x1bd0b8ff5cb4b081!2sLe%20Poivrier%20-%20Le%20Catamaran!5e1!3m2!1sen!2smu!4v1667529618547!5m2!1sen!2smu" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Footer

