import React, {useState, useRef, useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import {HashLink} from "react-router-hash-link"

import Footer from './layout/Footer';

// LANGUAGE
import { useLanguageStore } from './subcomponents/LanguageStore';
import { tradJardin } from './translator/le_jardin/tradLeJardin';
import { HomeTrad } from './translator/homeTrad';
import {restaurantID} from "./translator/id"


// ICONS
import { ImLocation } from "react-icons/im";
import { IoIosPhonePortrait } from "react-icons/io";

//LOGOS
import LogoSansBg from '../assets/pictures/logos/logo_sans_bg.png'
import LogoJardin from "../assets/pictures/logos/logo_jardin_sans_bg.png"
import LogoPlage from "../assets/pictures/logos/logo_plage_sans_bg.png"
import LogoIlePlate from "../assets/pictures/logos/logo_ile_plate_nobg.png"
import LogoRiviere from "../assets/pictures/logos/logo_riviere_no_bg.png"


// SHOOTING
import PhotoJardin from '../assets/pictures/le_jardin/shooting/hutte.jpg'
import PhotoRiviere from "../assets/pictures/la_riviere/entree_paysage.jpg"
import PhotoIlePlate from "../assets/pictures/lile_plate/plage_haut.jpg"
import PhotoPlage from "../assets/pictures/la_plage/fauteuils_paysage.jpg"
import FishTable from "../assets/pictures/le_jardin/shooting/fish_table.jpg"
import Risotto from "../assets/pictures/le_jardin/shooting/risotto_cognac.jpg"
import MousseChoco from "../assets/pictures/le_jardin/shooting/mousse_choco.jpg"
import Rhums from "../assets/pictures/le_jardin/shooting/rhums.jpg"
import Gourmand from "../assets/pictures/le_jardin/shooting/gourmand.jpg"
import Carpaccio from '../assets/pictures/le_jardin/shooting/carpaccio_zoom.jpg'

function Home2 () {
    const language = useLanguageStore((state) => state.language)
    document.title="Le POIVRIER";
    document.head.querySelector("[id~=page-description][content]").content="Le Poivrier, check-out our restaurants, four settings: an island, a beach, a river and a garden. Each restaurant offers its unique setting and ambiance, allowing guests to enjoy a diverse dining experience across these four locations.";
    return (
        <div className='page_container' id="accueil">
            <div className="fake_scrolling">
                <div className="home_hero"></div>
                <div className="home_logo_container">
                    <img className="home_logo" src={LogoSansBg}/>
                </div>
            </div>
            
            {/* <div className="description">
                <span>"</span>
                Bien sûr, ici, on se délecte de plâts hors du commun, on partage une bonne bouteille de vin et on lève son verre à la beauté de Maurice. <br/>Mais surtout, on vie notre passion, on s’amuse et on profite de la vie.
                <span>"</span>
            </div> */}
            <div className="home_scrolling">
                <div className="restaurants_section_container">
                <div className="restaurant_section">
                    <div className="restau_photo_container">
                    <img src={PhotoJardin} className='photo_restau' alt="" />
                    </div>
                    <div className="description_container">
                        
                        <div className="text_container">
                        <h3>Le Poivrier Le Jardin</h3>
                            <div className="separator"></div>
                            <p className='restau_description_p' >{HomeTrad[language].jardinp1}</p>
                            <p className='restau_description_p' >{HomeTrad[language].jardinp2}</p>
                            <p className='restau_description_p' >{HomeTrad[language].jardinp3}</p>
                            <br/>
                            <NavLink className="see_more_btn" to="/lejardin">{HomeTrad[language].voirPlus}</NavLink>
                        </div>
                   </div>
                </div>
                <div className="restaurant_section reverse">
                    <div className="description_container">
                        
                        <div className="text_container">
                        <h3>Le Poivrier L'île Plate</h3>
                            <div className="separator"></div>
                            <p>{HomeTrad[language].ilep1}</p>
                            <p>{HomeTrad[language].ilep2}</p>
                            <p>{HomeTrad[language].ilep3}</p>
                            <br/>
                            <NavLink className="see_more_btn" to="/ile_plate">{HomeTrad[language].voirPlus}</NavLink>
                        </div>
                    </div>
                    <div className="restau_photo_container">
                    <img src={PhotoIlePlate} className='photo_restau' alt="" />
                    </div>
                </div>
                <div className="restaurant_section">
                <div className="restau_photo_container">
                    <img src={PhotoRiviere} className='photo_restau' alt="" />
                </div>
                    <div className="description_container">
                    <div className="text_container">
                            <h3>Le Poivrier La Rivière</h3>
                            <div className="separator"></div>
                            <p>{HomeTrad[language].rivierep1}</p>
                            <p>{HomeTrad[language].rivierep2}</p>
                            <br/>
                            <NavLink className="see_more_btn" to="/lariviere">{HomeTrad[language].voirPlus}</NavLink>
                        </div>
                    </div>     
                </div>
                <div className="restaurant_section reverse"> 
                    <div className="description_container">
                        <div className="text_container">
                        <h3>Le Poivrier La Plage</h3>
                        <div className="separator"></div>
                            <p>{HomeTrad[language].plagep1}</p>
                            <p>{HomeTrad[language].plagep2}</p>
                            <p>{HomeTrad[language].plagep3}</p>
                            <br/>
                            <NavLink className="see_more_btn" to="/laplage">{HomeTrad[language].voirPlus}</NavLink>
                        </div>
                    </div>
                    <div className="restau_photo_container">
                        <img src={PhotoPlage} className='photo_restau' alt="" />
                    </div>
                    
                </div>
                </div> 


                <div className="home_contact_container" id="contact">
                    <div className="flex_wrap_container">
                        <div className="contact_one_restau_container">
                            <div className="logo_container">
                            <br/><br/><img src={LogoJardin} alt="" />
                            </div>
                            
                            <div className="description_contact">
                                <div className="paragraph_container">
                                    <div className="p_icon_container">
                                    <ImLocation className="icon_contact" size={20}/>
                                        <p>{restaurantID[language].jardinAdress}</p>
                                    </div>
                                    <div className="p_icon_container">
                                        <IoIosPhonePortrait className="icon_contact" size={20}/>
                                        <p>{restaurantID[language].jardinPhone}</p>
                                    </div>
                                    
                                    <br/>
                                    
                                    <HashLink className="contact_us_btn" to="/lejardin#contact_lejardin" smooth>{HomeTrad[language].contact}</HashLink>
                                </div>
                                
                            </div>
                           
                        </div>
                        <div className="contact_one_restau_container">
                            <div className="logo_container">
                            <img src={LogoIlePlate} alt="" />  
                            </div>
                            
                            <div className="description_contact">
                                <div className="paragraph_container">
                                <div className="p_icon_container">
                                    <ImLocation className="contact_icon" size={20}/>
                                       <p>{restaurantID[language].ilePlateAdress}</p>
                                    </div>
                                    <div className="p_icon_container">
                                        <IoIosPhonePortrait className="contact_icon" size={20}/>
                                        <p>{restaurantID[language].ilePlatePhone}</p>
                                    </div>
                                     
                                    <br/>
                                    <HashLink className="contact_us_btn" to="/ile_plate#contact_ileplate" smooth>{HomeTrad[language].contact}</HashLink>   
                                </div>
                                
                            </div>
                    
                        </div>
                        <div className="contact_one_restau_container">
                            <div className="logo_container">
                            <img src={LogoRiviere} alt="" />
                            </div>
                            
                            <div className="description_contact">
                                <div className="paragraph_container">
                                <div className="p_icon_container">
                                    <ImLocation className="contact_icon" size={20}/>
                                        <p>{restaurantID[language].riviereAdress}</p>
                                    </div>
                                    <div className="p_icon_container">
                                        <IoIosPhonePortrait className="contact_icon" size={20}/>
                                        <p>{restaurantID[language].rivierePhone}</p> 
                                    </div>
                                    
                                    <br/>
                                    <HashLink className="contact_us_btn" to="/lariviere#contact_lariviere" smooth>{HomeTrad[language].contact}</HashLink>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="contact_one_restau_container">
                            <div className="logo_container">
                            <img src={LogoPlage} alt="" /> 
                            </div>
                            
                            <div className="description_contact">
                                <div className="paragraph_container">
                                <div className="p_icon_container">
                                    <ImLocation className="contact_icon" size={20}/>
                                        <p>{restaurantID[language].plageAdress}</p>
                                    </div>
                                    <div className="p_icon_container">
                                        <IoIosPhonePortrait className="contact_icon" size={20}/>
                                        <p>{restaurantID[language].plagePhone}</p>
                                    </div>
                                    
                                    <br/>
                                    <HashLink className="contact_us_btn" to="/laplage#contact_laplage" smooth>{HomeTrad[language].contact}</HashLink>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>                
            </div>
            <div className="mini_gallery_jardin">
                <div className="mini_gallery_wrap">
                    <img src={Risotto} alt="" />
                    <img src={Carpaccio} alt="" />
                    <img src={FishTable} alt="" />
                    <img src={MousseChoco} alt="" />
                    <img src={Rhums} alt="" />
                    <img src={Gourmand} alt="" />
                </div>
            </div>
            </div>
            
        <Footer/>
        </div>
    )
}

export default Home2

// Le Poivrier :
// Le Poivrier est le restaurant phare de notre chaîne, offrant une expérience culinaire exceptionnelle dans un cadre élégant et chaleureux. Notre équipe de chefs talentueux met en valeur les saveurs et les textures des ingrédients de saison pour créer des plats raffinés qui raviront vos papilles. Que vous soyez à la recherche d'un dîner romantique, d'une soirée entre amis ou d'un repas d'affaires, Le Poivrier est l'endroit idéal pour savourer une cuisine exquise accompagnée d'une sélection de vins soigneusement choisie. Notre attention au détail et notre service impeccable font de chaque visite une expérience mémorable.

// Le Poivrier Le Jardin :
// Le Poivrier Le Jardin vous transporte dans un cadre paisible et verdoyant, où vous pourrez savourer une cuisine raffinée dans une ambiance détendue.Niché au cœur d'un magnifique jardin luxuriant, ce restaurant offre une expérience culinaire unique. Notre équipe de chefs talentueux met en avant les saveurs de saison pour créer des plats délicieux qui éveilleront vos papilles. De plus, notre chef sushi hautement qualifié propose une carte sushi exceptionnelle qui ravira les amateurs de cuisine japonaise. Laissez-vous séduire par la fraîcheur des poissons et la créativité des compositions, dans un cadre enchanteur où la nature et la gastronomie se rejoignent harmonieusement. Le Poivrier Le Jardin est l'endroit idéal pour une escapade culinaire mémorable.

// Le Poivrier La Plage :
// Le Poivrier La Plage vous transporte dans un cadre idyllique en bord de mer, où vous pourrez déguster notre cuisine raffinée tout en admirant la vue imprenable sur l'océan. Notre restaurant de plage offre une expérience gastronomique inoubliable. Laissez-vous emporter par la brise marine et les saveurs délicates de nos plats préparés avec passion.

// Le Poivrier L'Ile Plate :
// Bienvenue au Poivrier L'île Plate, où la cuisine de première qualité rencontre l'histoire fascinante de l'île. Venez découvrir notre restaurant pittoresque où vous pourrez déguster une cuisine française innovante et élégante tout en admirant les ruines de l'époque des pirates.Que vous recherchiez une escapade romantique ou une occasion spéciale, Le Poivrier L'Ile Plate vous offre une expérience hors du commun. Laissez-vous emporter par la brise marine et les saveurs délicates de nos plats préparés avec passion.

// Le Poivrier La Rivière :
// Le Poivrier La Rivière est niché au bord d'une rivière paisible, offrant un cadre charmant et serein pour profiter de notre cuisine raffinée. Entouré par la nature luxuriante, vous pourrez savourer des plats préparés avec soin, alliant des techniques culinaires modernes à des ingrédients locaux de qualité. Que vous soyez en quête d'une escapade culinaire tranquille ou d'une expérience romantique au son apaisant de l'eau, Le Poivrier La Rivière vous invite à Tamarin, vous détendre et vous régaler.

// 