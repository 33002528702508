export const tradFooter = {
    français: {
        contact: "contact / info",
        openings: "Horaires", 
        location: 'nous trouver',
        closed: "fermé"
    },
    english: {
        contact: "contact / info",
        openings: "opening days / hours", 
        location: 'find us',
        closed: "closed"
    },
    pусский: {
        contact: "контакты / информация",
        openings: "время открытия",
        location: "мы находимся",
        closed: "выходной",
    }
}


export const week = {
    français: {
        monday: 'lundi',
        tuesday: 'mardi',
        wednesday: "mercredi",
        thursday: "jeudi",
        friday: 'vendredi', 
        saturday: 'samedi',
        sunday: 'dimanche'
    },
    english: {
        monday: 'monday',
        tuesday: 'tuesday',
        wednesday: "wednesday",
        thursday: "thursday",
        friday: 'friday', 
        saturday: 'saturday',
        sunday: 'sunday'
    },
    pусский: {
        monday: "понедельник",
        tuesday: "вторник",
        wednesday: "среда",
        thursday: "четверг",
        friday: "пятница",
        saturday: "суббота",
        sunday: "воскресенье",
    }
}

